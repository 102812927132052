@import "/src/shared-styles/variables/general.scss";

.accept-welcome-container {
  .greeting {
    margin-bottom: 0px;
    text-align: center;
  }

  .modal-message {
    margin-bottom: 15px;
    text-align: center;
  }
}
