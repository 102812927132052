@import "/src/shared-styles/variables/general.scss";

.aa-Autocomplete {
  @media #{$media-medium} {
    width: 14rem; 
  }

  @media #{$media-wide} {
    width: 20rem;
  }

  .aa-Form {
    border-color: rgb(166, 170, 173);
  }
}

.aa-Panel {
  position: fixed;
  z-index: 100;

  @media only screen and (min-width: 42.6rem) {
    top: 51.25px !important;
  }

  @media #{$media-medium} {
    width: 14rem;
    max-width: 14rem; 
  }

  @media #{$media-wide} {
    width: 20rem;
    max-width: 20rem;
  }
}