@import '/src/shared-styles/variables/general.scss';

.user-icon {
  margin-right: 12px;

  &__icon {
    display: flex;
    align-items: center;
    width: 40px;
    height: 40px;
    min-width: 40px;
    border-radius: 2px;
    color: $color-white;
    font-family: $font-family-roboto;
    font-size: 20px;
    font-weight: $font-weight-bold;
    text-align: center;
    text-transform: uppercase;

    &--teal {
      background-color: $org-db-color-teal;
    }

    &--blue {
      background-color: $color-exclusive-blue
    }
    &--red {
      background-color: $color-brand;
    }
    &--green {
      background-color: $color-green;
    }
    &--orange {
      background-color: $org-db-color-orange;
    }
  }

  &__initials {
    width: 100%;
    text-align: center;
  }
}