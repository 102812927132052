.table__wrapper {
  overflow-x: scroll;

  &.equalWidthColumns {
    table {      
      table-layout: fixed;
      width: 100%;
    }
  }

  table{
    border-spacing:0;
    column-gap: 0;
    border: 1px solid #F8F8F8;
  }

  .table__name {
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 1rem;
  }

  .table__description {
    padding-bottom: 2rem;
    margin-bottom: -1rem;
  }

  tr:first-of-type {
    background: #F8F8F8;
    
    th {
      border-right: 1px solid #595959;
      &:last-of-type{
        border-right: none;
      }
    }
  }

  th {
    border-bottom: 1px solid black;
    font-weight: 600;
  }

  td, th {
    padding: 0 1rem;
    font-size: 14px;
  }

  tr + tr {
    td {
      border-bottom: 1px solid #D1D1D1;
      border-right: 1px solid #D1D1D1;
      margin: 0 1rem;
    }

    td:last-child {
      border-right: 0;
    }
  }

  tr + tr:last-child {
    td {
      border-bottom: 0;
    }
  }
}