.nav-icon {
  z-index: 50;

  @media only screen and (min-width: 64.68em) {
    height: 56px;
  }

  @media only screen and (min-width: 48em) and (max-width: 64.68em) {
    height: 56px;
  }
}

.nav-icon__menu--sticky {
  @media only screen and (min-width: 48em) and (max-width: 64.68em) {
    min-width: initial;
    max-width: initial;
    padding: 0 49px;
  }
}

.nav-links__icon,
.nav-icon__divider,
.nav-icon__jump {
  -ms-flex-item-align: center;
  align-self: center;
}

.nav-icon__jump,
.nav-icon__menu {
  font-size: 16px;
  font-family: "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
}

.nav-icon__menu {
  display: -ms-flexbox;
  display: flex;
  z-index: 3;
  width: 100%;
  height: 56px;
  transition: position linear 0.2s;
  background-color: #fff;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.1);

  @media only screen and (min-width: 48em) and (max-width: 64.68em) {
    -ms-flex-pack: distribute;
    justify-content: space-around;
    min-width: initial;
    max-width: initial;
    padding: 0 49px;
  }

  @media only screen and (max-width: 47.999em) {
    position: fixed;
    top: 150px;
    left: 0;
    -ms-flex-pack: initial;
    justify-content: initial;
    width: 134px;
    min-width: initial;
    max-width: initial;
    height: 103px;
    padding: 7px;
    transition: linear left 0.05s;
  }
}

@media only screen and (max-width: 47.999em) {
  .nav-icon__menu--collapsed {
    left: -134px;
  }
}

.nav-icon__menu-inner {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  max-width: 1440px;
  margin: 0 auto;
}

.nav-icon__content {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  min-width: 750px;
  max-width: 750px;
  margin: 0 auto;

  @media only screen and (min-width: 48em) and (max-width: 64.68em) {
    min-width: initial;
    max-width: initial;
    margin: 0;
  }

  @media only screen and (max-width: 47.999em) {
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: initial;
    max-width: initial;
    margin: 0;
  }
}

.nav-icon__jump {
  @media only screen and (min-width: 1441px) {
    left: 280px;
  }

  @media only screen and (min-width: 48em) and (max-width: 64.68em) {
    font-size: 14px;
  }

  @media only screen and (max-width: 47.999em) {
    position: absolute;
    top: 26px;
    right: -50px;
    left: initial;
    height: auto;
    padding: 5px 10px;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    background-color: #fff;
    font-size: 14px;
  }
}

.nav-links__options {
  display: flex;
  align-items: center;
  justify-content: space-around;

  :hover {
    color: #a6aaad !important;
  }
}

.nav-links__link-wrapper {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  height: 100%;
  font-family: "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 16px;
  text-transform: uppercase;
  cursor: pointer;
  margin-right: 15px;
  color: #a6aaad;
  font-weight: 400;
  text-decoration: none !important;

  @media only screen and (max-width: 47.999em) {
    min-width: initial;
    margin-bottom: 5px;
    font-size: 12px;
  }
}

.nav-links__link-wrapper--active {
  div {
    border-bottom: 1px solid #1e90ba;
  }

  p {
    color: #1e90ba;
  }

  img {
    filter: invert(42%) sepia(80%) saturate(479%) hue-rotate(150deg)
      brightness(97%) contrast(90%);
  }
}

.nav-links__link-content {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding-bottom: 2px;
  border-bottom: 1px solid #fff;
}

.nav-links__icon {
  display: -ms-flexbox;
  display: flex;
  margin-right: 5px;
}

.nav-links__link {
  margin-top: 0px;
  margin-bottom: 0px;
}

.nav-icon__divider {
  display: -ms-flexbox;
  display: flex;
  color: #a6aaad;
}
