@import "/src/shared-styles/variables/general.scss";

.b-input__error {
  height: 16px;
  color: $color-red;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
}

.invite-users-modal-header {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 24px;
}