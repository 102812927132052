@import '/src/shared-styles/layout.scss';

// Table styles are subject to change, redesign is coming at some point in the project
.table-wrapper {
  box-sizing: border-box;
  width: 100%;
  padding: 60px 118px 89px;
  background-color: $color-pale-grey;

  @media #{$media-medium} {
    height: initial;
    padding: 36px 118px 51px;
  }

  @media #{$media-small} {
    height: initial;
    padding: 21px 18px 32px;
  }

  table {
    border: none;
    background-color: $color-white;
    color: $color-grey-dark;
    font-family: $font-family-roboto;
    line-height: 2.75;
    text-transform: initial;
  }

  tr {
    border: none;
    border-bottom: 1px solid $color-light-grey;
  }

  th {
    padding-top: 12px;
    padding-bottom: 12px;
    border: none;
    font-size: 18px;
    font-weight: $font-weight-bold;
    text-align: center;
    text-transform: initial;
  }

  td {
    padding-top: 16px;
    padding-bottom: 16px;
    border: none;
    background-color: $color-white;
    font-size: 18px;
    font-weight: $font-weight-light;
    text-align: center;
    text-transform: initial;
  }
}


.table-header {
  color: $color-grey-dark;
  font-family: $font-family-rubik;
  font-size: 40px;
  font-weight: $font-weight-light;
  text-align: center;

  @media #{$media-medium} {
    font-size: 28px;
  }

  @media #{$media-small} {
    font-size: 18px;
  }

  &--blue {
    color: $color-turqoise;
  }
}

.table-body {
  margin-bottom: 39px;
  font-family: $font-family-roboto;
  font-size: 18px;
  font-weight: $font-weight-bold;
  text-align: center;

  @media #{$media-small} {
    margin-bottom: 21px;
    font-size: 7px;
  }
}

.table {
  max-width: 715px;
  margin: 0 auto;
  padding: 26px 32px;
  background-color: $color-white;

  @media #{$media-small} {
    padding: 26px 15px;
    overflow-x: scroll;
  }
}

.table-teaser {
  position: relative;
}

.table-image {
  width: 100%;

  @media #{$media-small} {
    max-width: 100%;
  }
}

.table-banner {
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  width: 501px;
  height: 139px;
  margin-right: auto;
  margin-left: auto;
  padding: 20px;
  transform: translateY(-50%);
  background-image: $gradient-managed-data-small;
  box-shadow: 0 15px 12px 0 $color-landing-page-box-shadow;
  text-align: center;
  text-transform: uppercase;

  @media #{$media-small} {
    width: 300px;
    height: 110px;
    padding: 14px 17px;
  }
}

.banner-header {
  margin-bottom: 20px;
  color: $color-white;
  font-family: $font-family-roboto;
  font-size: 31px;
  font-weight: $font-weight-light;

  @media #{$media-small} {
    margin-bottom: 12px;
    font-size: 20px;
  }
}

.buttons {
  display: flex;
  justify-content: space-between;
  width: 62%;
  margin: 0 auto;

  &--centered {
    justify-content: space-around;
  }

  @media #{$media-small} {
    width: 80%;
  }
}

.button {
  padding: 5px 15px;
  border-width: 2px;
  font-family: $font-family-roboto;
  font-size: 13px;
  font-weight: $font-weight-bold;
  letter-spacing: 0.65px;

  @media #{$media-small} {
    font-size: 11px;
  }
}

.ranking-indicator {
  margin-left: 8px;
  width: 9px;

  &--neutral {
    margin-bottom: 5px;
  }
}
