.basic__rich-page-section {
  h2 {
    font-weight: 300;
    color: #1b1b1b;
    font-size: 40px;
    line-height: 1.4;
    margin-top: 120px;
    margin-bottom: 0;

    @media only screen and (min-width: 48em) and (max-width: 64.68em) {
      font-size: 32px;
    }

    @media only screen and (max-width: 47.999em) {
      font-size: 28px;
    }
  }

  h3 {
    color: #1b1b1b;
    font-size: 18px;
    margin-top: 20px;
    margin-bottom: 0;
    font-weight: 500;
    line-height: 1.4;
  }

  p {
    color: #1b1b1b;
    font-size: 18px;
    margin-top: 4px;
    font-weight: 300;
    line-height: 1.6;
  }

  code {
    width: 100% !important;
    padding: 0.75rem !important;
    color: #594099 !important;
    font-size: 14px !important;
    font-family: Roboto Mono !important;
    line-height: 1.1 !important;
    font-weight: 300 !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
    border: 1px solid #e6e6e6 !important;
  }

  ul,
  ol {
    p {
      margin: 4px 0 10px;
    }
    ol {
      list-style-type: upper-alpha;
      ol {
        list-style-type: lower-roman;
      }
    }
  }

  img {
    margin-bottom: 10px;
    max-width: 100%;
    height: auto;
  }

  .basic__rich-button {
    margin: 10px 0;
  }

  .basic__rich-fact-row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 1440px;
    padding: 0 118px;

    @media only screen and (min-width: 64.68em) and (max-width: 1680px) {
      margin: 0 0 48px;
    }

    @media only screen and (min-width: 48em) and (max-width: 64.68em) {
      display: block;
      width: 100%;
    }

    @media only screen and (max-width: 47.999em) {
      display: block;
      max-width: 346px;
      padding: 0;
    }
  }

  .basic__rich-t-grid-column-wide {
    width: 65%;

    @media only screen and (max-width: 47.999em) {
      width: auto;
    }
  }

  .basic__rich-page-code {
    word-break: break-all;
    white-space: pre-wrap;
  }
}
