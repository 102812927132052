.short-structured-section-wrapper {
  position: relative;
}

.header + .short-structured-section-wrapper {
  @media only screen and (min-width: 48em) and (max-width: 64.68em) {
    margin-top: 55px;
  }

  @media only screen and (max-width: 47.999em) {
    margin-top: 75px;
  }
}

.basic__rich-page-section + .short-structured-section-wrapper {
  margin-top: -48px;
}

.header-dark-background {
  background-color: #000;

  .short-structured-market-header {
    color: #fff;
  }

  .short-structured-market-header--notice {
    color: #fec800;
  }

  .short-structured-market-body {
    color: #fff;
  }

  .short-structured-market-subheading {
    color: #fff;
  }
}

.header-light-background {
  background-color: #fff;

  .short-structured-market-header--notice {
    color: #fec800;
  }

  .short-structured-market-header {
    color: #0d3244;
  }

  .short-structured-market-body {
    color: #0d3244;
  }

  .short-structured-market-subheading {
    color: #0d3244;
  }
}

.short-structured-section-content {
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -ms-flex-pack: justify;
  justify-content: space-between;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 118px;

  @media only screen and (min-width: 1441px) {
  }

  @media only screen and (min-width: 48em) and (max-width: 64.68em) {
    max-width: 672px;
  }

  @media only screen and (max-width: 47.999em) {
    max-width: 320px;
    padding: 0;
  }
}

.short-structured-section-content--column {
  -ms-flex-direction: column;
  flex-direction: column;
}

.short-structured-market-header-container {
  margin-top: 120px;
  @media only screen and (min-width: 48em) and (max-width: 64.68em) {
    margin-top: 65px;
    margin-bottom: 15px;
  }

  @media only screen and (max-width: 47.999em) {
    margin-top: 45px;
    margin-bottom: 10px;
    text-align: center;
  }
}

.short-structured-market-header {
  display: inline;
  font-size: 36px;
  font-weight: 700;
  line-height: 1.4;

  @media only screen and (min-width: 48em) and (max-width: 64.68em) {
    font-size: 36px;
    line-height: 40px;
  }

  @media only screen and (max-width: 47.999em) {
    font-size: 28px;
    line-height: 34px;
  }
}

.short-structured-market-header.header-flipped {
  font-size: 40px;
  line-height: 1.4;
  color: #1b1b1b;
  font-weight: 300;
  opacity: 0;

  @media only screen and (min-width: 48em) and (max-width: 64.68em) {
    font-size: 32px;
  }

  @media only screen and (max-width: 47.999em) {
    font-size: 28px;
  }

  .with-top-blue-line {
    position: relative;
    padding-top: 5px !important;
  }

  .with-top-blue-line::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 60px;
    width: 30px;
    height: 1.5px;
    background-color: #0090ba;
  }

  .with-top-blue-line--markdown::before {
    top: 7px;
  }
}

.short-structured-market-header--notice {
  margin-right: 5px;
  text-transform: uppercase;
}

.short-structured-market-body {
  font-size: 40px;
  font-weight: 300;
  line-height: 48px;

  @media only screen and (min-width: 48em) and (max-width: 64.68em) {
    max-width: 450px;
    font-size: 24px;
    line-height: 27px;
  }

  @media only screen and (max-width: 47.999em) {
    margin-bottom: 10px;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
  }
}

.short-structured-market-body.body-flipped {
  font-size: 20px;
  color: #1b1b1b;
  line-height: 1.6;
  margin-top: 20px;
  opacity: 0;

  @media only screen and (max-width: 47.999em) {
    font-size: 18px;
    line-height: 1.1;
  }
}

.short-structured-market-subheading {
  color: #fff;
  font-size: 24px;
  font-weight: 400;
  line-height: 1.6;
  padding-bottom: 60px;

  @media only screen and (max-width: 47.999em) {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
  }
}

.short-structured-market-image {
  margin-left: 10px;
  height: 22px;

  @media only screen and (max-width: 47.999em) {
    margin-top: 10px;
    margin-left: 0;
  }
}

.header-flipped.flipped__text__visible,
.body-flipped.flipped__text__visible {
  opacity: 0;
  animation: fade-in 0.25s ease-in-out 0.125s;
  animation-fill-mode: forwards;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
