.cta__graphic-wrapper {
  .cta__graphic-fact-row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin: 0 auto 20px;
    max-width: 1440px;
    padding: 0 118px;

    @media only screen and (min-width: 48em) and (max-width: 64.68em) {
      max-width: 672px;
    }

    @media only screen and (max-width: 47.999em) {
      display: block;
      padding: 0;
      max-width: 346px;
    }
  }

  .cta__graphic__survey-row {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;

    @media only screen and (min-width: 48em) and (max-width: 64.68em) {
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
    }

    @media only screen and (max-width: 47.999em) {
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
    }
  }

  .cta__graphic {
    @media only screen and (min-width: 48em) and (max-width: 64.68em) {
      width: 100%;
    }

    @media only screen and (max-width: 47.999em) {
      max-width: 100vw;
    }
  }

  .cta__graphic-header {
    font-size: 36px;
    font-weight: 300;
    margin-top: 0px;
    margin-bottom: 20px;
    line-height: 1.4;

    @media only screen and (max-width: 47.999em) {
      font-size: 28px;
    }
  }

  .cta__graphic-body {
    font-size: 19px;
    font-weight: 300;
    line-height: 1.6;
    margin-top: 0px;
    margin-bottom: 0px;

    @media only screen and (max-width: 47.999em) {
      font-size: 18px;
    }
  }

  .cta__graphic-overlapping-items {
    position: relative;
    width: 660px;
    height: 520px;
    margin-right: 40px;

    @media only screen and (min-width: 64.6799em) and (max-width: 1200px) {
      width: 500px;
    }

    @media only screen and (max-width: 47.999em) {
      width: auto;
      height: initial;
      margin-bottom: 80px;
      margin-right: 0;
    }
  }

  .cta__graphic-image-item {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: 90%;

    @media only screen and (max-width: 47.999em) {
      position: relative;
      width: 100%;
    }
  }

  .cta__graphic-text-item {
    position: absolute;
    z-index: 1;
    right: 0;
    bottom: 20px;
    width: 80%;
    padding: 38px 60px 64px 40px;
    color: #fff;
    right: -30px;

    @media only screen and (min-width: 64.6799em) and (max-width: 1200px) {
      bottom: -30px;
    }

    @media only screen and (min-width: 48em) and (max-width: 64.68em) {
      right: -20px;
    }

    @media only screen and (max-width: 47.999em) {
      position: relative;
      width: auto;
      right: 0;
      top: -5px;
    }
  }

  .cta__graphic-background__blue_purple {
    background-image: linear-gradient(112deg, #31c6ca, #692a99 98%);
  }

  .cta__graphic-background__red_purple {
    background-image: linear-gradient(115deg, #dd591d, #9922ae);
  }

  .cta__graphic-background__green_blue {
    background-image: linear-gradient(
      100deg,
      #6db43e -1%,
      #00529a 100%,
      #00529a 100%
    );
  }

  .survey {
    width: 350px;

    @media only screen and (min-width: 48em) and (max-width: 64.68em) {
      width: 100%;
      margin-top: 65px;
    }
  }

  .survey-wrapper {
    height: 250px;
    padding: 34px 22px 43px;
    box-shadow: 0 15px 12px 0 rgba(27, 27, 27, 0.22);
    text-align: center;
    border: 1px solid #e6e6e6;

    @media only screen and (max-width: 47.999em) {
      margin-top: 65px;
      margin: 0 auto;
      width: initial;
    }

    h2 {
      color: #1e90ba;
      font-weight: 300;
      margin-top: 0px;
      margin-bottom: 30px;
      line-height: 1.14;
      font-size: 20px;

      @media only screen and (min-width: 48em) and (max-width: 64.68em) {
        font-size: 32px;
      }
    }

    h2:not(:first-of-type) {
      border-top: 1px solid #e6e6e6;
      padding-top: 24px;
    }

    p {
      font-weight: 300;
      margin-top: 0px;
      margin-bottom: 10px;
      font-size: 14px;

      @media only screen and (min-width: 48em) and (max-width: 64.68em) {
        font-size: 22px;
      }
    }

    ul {
      margin-left: -6px;
    }

    ul p {
      margin-top: 0px;
      margin-bottom: 0px;
      line-height: 1.6;
    }
  }

  .survey-wrapper--tall {
    text-align: left;
    height: initial;
    padding: 34px 22px 19px;

    @media only screen and (min-width: 48em) and (max-width: 64.68em) {
      -ms-flex-preferred-size: 100%;
      flex-basis: 100%;
    }
  }

  .cta__section__right__button {
    display: flex;
    flex-direction: column;
    padding: 0 22px;
    text-align: center;
    margin-bottom: 24px;

    @media only screen and (min-width: 48em) and (max-width: 64.68em) {
      align-items: flex-start;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}
