.cta-button {
  width: 100%;
  margin: auto;
  line-height: 1.2;

  &__wrapper {
    padding: 0 118px 70px;
    @media only screen and (min-width: 48em) and (max-width: 64.68em) {
      height: initial;
      padding: 78px 118px 70px;
    }

    @media only screen and (max-width: 47.999em) {
      height: initial;
      padding: 54px 17px 75px;
    }
  }

  &__banner {
    padding: 28px 29px 44px;
    border-radius: 6px;
    box-shadow: 0 15px 12px 0 rgba(27, 27, 27, 0.22);

    @media only screen and (min-width: 48em) and (max-width: 64.68em) {
      line-height: 3rem;
    }

    @media only screen and (max-width: 47.999em) {
      padding: 21px 29px 35px;
    }
  }

  .cta__button-background__blue_purple {
    background: linear-gradient(102deg, #1c818c, #501b73 100%);
  }

  .cta__button-background__green_blue {
    background: linear-gradient(
      100deg,
      #6db43e -1%,
      #00529a 100%,
      #00529a 100%
    );
  }

  &__create-header {
    text-transform: capitalize;
    color: #fff;
    font-size: 18px;
    font-weight: 300;
    margin-bottom: 5px;
    margin-top: 0;
  }

  &__banner-header {
    color: #fff;
    font-size: 48px;
    font-weight: 300;
    margin-bottom: 0;
    margin-top: 0;

    @media only screen and (min-width: 48em) and (max-width: 64.68em) {
      font-size: 42px;
    }

    @media only screen and (max-width: 47.999em) {
      font-size: 24px;
      margin-bottom: 10px;
    }
  }

  &__banner-body {
    margin-bottom: 25px;
    margin-top: 0px;
    color: #fff;
    font-size: 24px;
    font-weight: 300;

    @media only screen and (max-width: 47.999em) {
      font-size: 18px;
    }
  }

  &__buttons {
    display: -ms-flexbox;
    display: flex;

    @media only screen and (max-width: 47.999em) {
      padding: 5px 7px;
    }

    :first-child {
      margin-right: 15px !important;
    }
  }
}
