@import '/src/shared-styles/variables/general.scss';

@keyframes progressBarStripes {
  from { background-position: 0 0; }
  to { background-position: 24px 0; }
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes stripes {
  from { background-position: 0 0; }
  to { background-position: 40px 0; }
}


// Originally from Animate.css - http://daneden.me/animate
.animated {
  animation-duration: 0.6s;
  animation-fill-mode: both;

  &.fade-in {
    animation-name: fadeIn;
    animation-duration: 5s;
  }

  &.zoom-in {
    animation: zoomIn 0.3s 0.3s ease-in-out backwards;
  }

  &.zoom-out {
    display: none;
    animation: zoomOut 0.3s ease-in-out forwards;
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translate3d(0, -20%, 0);
  }

  100% {
    opacity: 1;
    transform: none;
    display: none;
  }
}

@keyframes zoomOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: scale3d(0.90, 0.90, 0.90);
  }
}


@keyframes zoomIn {
  0% {
    opacity: 0;
    transform: scale3d(0.90, 0.90, 0.90);
  }

  100% {
    opacity: 1;
  }
}

@keyframes globalLoadingProgress {
  0% { width: 0; }
  100% { width: 100%; }
}
