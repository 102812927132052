@import "/src/shared-styles/variables/general.scss";

.headerContainer {
  padding: 0 1.5rem;
}

.formHint {
  color: $color-bright-blue;
  font-size: 1.125rem;
  font-weight: $font-weight-light;
  font-family: $font-family-rubik;
  margin-top: -0.625rem;
  margin-bottom: 0.25rem;
}

.formHead {
  color: $color-grey-dark;
  font-size: 1.875rem;
  line-height: 1em;
  font-weight: $font-weight-light;
  font-family: $font-family-rubik;
  margin-bottom: 0;
  margin-top: 0.625rem;
}

.formHeadSub {
  color: $color-grey-xx-dark;
  font: 0.875rem;
  font-family: $font-family-roboto;
  font-weight: $font-weight-light;
  margin-top: 0.25rem;
}

.formItemContainer {
  display: flex;
  flex-wrap: wrap;
  padding: 0.5rem 0;
}

.formItem {
  flex: 50%;
  box-sizing: border-box;
  padding: 0 1.5rem 0.5rem 0.75rem;
  &:nth-child(odd) {
    max-width: 50%;
    padding-left: 1.5rem;
    padding-right: 0.75rem;
  }
  @media only screen and (max-width: 47.999em) {
    flex: 100%;

    &:nth-child(odd) {
      max-width: 100%;
    }
  }
}

.formSubmit {
  padding: 0.5rem 1.5rem 1.5rem;
  width: 100%;
}
