@import "/src/shared-styles/variables/general.scss";

.error-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 4rem 1rem;
    text-align: center;
    font-family: $font-family-source-sans-pro;
    -webkit-font-smoothing: antialiased;

    .error-page__heading {
        margin-bottom: 2rem;
        font-size: 33px;
        font-weight: 600;
        line-height: 1;
    }

    .search-box {
        width: 600px;
        margin: 1rem auto;
    }

    p {
        margin:0;
        padding:0;
        font-size: 16px;
        font-weight: 400;
        text-rendering: optimizeLegibility;
        line-height: 1.6;
    }

    ul.error-page__options {
        margin-bottom: 1rem;
        list-style-position: inside;
        font-size: 16px;
        line-height: 1.6;

        li {
            display: list-item;
            a {
                text-decoration: none;
            }
        }
    }
}

