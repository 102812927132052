.contentful-header {
  background-color: black;
  background-size: cover;
  width: 100%;
  background-repeat: no-repeat;
  margin-top: 1px;
  padding: 74px 0;

  &__section {
    display: flex;
    position: relative;
    justify-content: space-between;
    max-width: 1440px;
    margin: 0 auto;

    @media only screen and (min-width: 64.68em) {
      padding: 0 118px;
    }

    @media only screen and (min-width: 48em) and (max-width: 64.68em) {
      max-width: 672px;
      padding: 0 118px;
    }
    @media only screen and (max-width: 47.999em) {
      max-width: 320px;
      margin: 0 auto;
      padding-bottom: 0;
      flex-direction: column;
    }
  }

  &__container {
    width: 100%;

    @media only screen and (max-width: 47.999em) {
      text-align: center;
    }
  }

  &__title {
    max-width: 75%;
    margin-top: 0;
    color: #fff;
    margin-bottom: 0;
    font-size: 64px;
    font-weight: 600;
    letter-spacing: -0.02em;
    line-height: 1;

    @media only screen and (max-width: 64.68em) {
      text-align: left;
      font-size: 40px;
      line-height: 1.2;
    }
  }

  &__subtitle {
    max-width: 60%;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.6;
    margin-top: 26px;
    margin-bottom: 0;
    color: #fff;

    @media only screen and (min-width: 48em) and (max-width: 64.68em) {
      font-size: 18px;
      line-height: 29px;
      text-align: left;
      max-width: 100%;
    }

    @media only screen and (max-width: 47.999em) {
      font-size: 18px;
      line-height: 24px;
      text-align: left;
      max-width: 100%;
    }
  }

  &__primary-button {
    margin-top: 30px;

    @media only screen and (max-width: 47.999em) {
      text-align: left;
    }

    .link_button {
      @media only screen and (max-width: 47.999em) {
        margin-bottom: 15px;
      }
    }

    .header__action-button {
      display: inline-block;
      margin-right: 20px;
    }
  }

  &__media {
    width: 560px;
    height: auto;
    background-color: transparent;
    animation: transitionIn 0.5s;

    @media only screen and (min-width: 64.68em) and (max-width: 1680px) {
      width: 500px;
    }

    @media only screen and (min-width: 48em) and (max-width: 64.68em) {
      width: 320px;
    }

    @media only screen and (max-width: 47.999em) {
      width: 320px;
      margin-top: 0;
      margin-bottom: 0;
    }

    @keyframes transitionIn {
      from {
        opacity: 0;
        transform: rotateX(10deg);
      }

      to {
        opacity: 1;
        transform: rotateX(0);
      }
    }
  }
}
