.structured-text-quote {
  .quote-dark-background {
    background-color: #08062a;
  }

  .quote-light-background {
    background-color: #f5f5fa;

    .quote-label,
    .quote-heading,
    .quote-body,
    .quote-source {
      color: #08062a;
    }
  }

  .quote-container {
    max-width: 1248px;
    margin: 0 auto;
    padding: 40px 118px;

    @media (min-width: 48rem) {
      padding: 40px 118px;
    }
  }

  .quote-heading-container {
    text-align: center;
    padding: 12px;
  }

  .quote-label {
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 40px;
    line-height: 1.5;
  }

  .quote-heading {
    font-size: 54px;
    font-weight: 500;
    line-height: 1.185;
    letter-spacing: -0.02em;
    margin-top: 0;
    margin-bottom: 0;

    @media (max-width: 48rem) {
      font-size: 40px;
      line-height: 1.2;
      letter-spacing: -0.02em;
    }
  }

  .quote-body-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 20px;

    @media (max-width: 78rem) {
      padding: 40px 0;
    }
  }

  .quote-icon {
    height: 30px;
    color: #0092bc;
    margin-right: 30px;

    @media (max-width: 48rem) {
      height: 18px;
      margin-right: 10px;
    }
  }

  .quote-body {
    p {
      font-size: 40px;
      font-weight: 300;
      line-height: 1.2;
      letter-spacing: -0.02em;
      margin-top: 0;

      @media (max-width: 78rem) {
        font-size: 28px;
        line-height: 2.25rem;
      }

      @media (max-width: 48rem) {
        font-size: 18px;
        line-height: 1.2;
      }
    }
  }

  .quote-source {
    margin-top: 40px;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.5rem;
    letter-spacing: -0.02em;

    @media (max-width: 48rem) {
      margin-top: 30px;
      font-size: 12px;
      line-height: 1rem;
    }
  }

  .with-top-black-line {
    position: relative;
    padding-top: 24px !important;
  }

  .with-top-black-line::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 34px;
    height: 1.5px;
    background-color: #000;
  }

  .with-top-black-line--markdown::before {
    top: 7px;
  }
}