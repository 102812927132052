@import "/src/shared-styles/variables/general.scss";

.dataset-documentation {
  max-width: 1000px;
  max-height: calc(100vh - 100px);
  padding: 5px 30px 30px;
  overflow: scroll;

  &__title {
    color: $color-off-black;
    font-family: $font-family-quantico;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 24px;
    line-height: 1.4;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 14px;
    background-position: bottom;
    background-size: 6px 8px;

  }

  &__org-doc-section {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-top: 20px;
      margin-bottom: 16px;
      font-family: $font-family-quantico;
      font-weight: bold;
      text-transform: uppercase;
    }

    h1 {
      color: $color-primary-blue;
      font-size: 22px;
    }

    h2 {
      color: $color-off-black;
      font-size: 20px;
    }

    h3,
    h4,
    h5,
    h6 {
      color: $color-off-black;
      font-size: 14px;
    }

    ul {
      margin-left: 20px;
    }
  }

  // First h1 only needs a little space
  &__org-doc-section:nth-child(1) > h1 {
    margin-top: 20px;
  }
}