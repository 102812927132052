.tableGroups__wrapper {
  .tableGroup__name {
    font-family: Roboto;
    font-size: 22px;
    font-weight: 300;
  }

  .tableGroup__content {
    margin-bottom: 3rem;
  }
}
