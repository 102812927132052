.accordion-link-wrapper {
  .dark-background {
    background-color: #08062a;

    .accordion-heading {
      color: white;
    }

    .accordion-border {
      border-top: 1px solid #282742;
    }

    .accordion-item {
      border-bottom: 1px solid #282742;
    }
  }

  .light-background {
    background-color: #f5f5fa;

    .accordion-heading {
      color: #09062a;
    }

    .accordion-border {
      border-top: 1px solid #dddde5;
    }

    .accordion-item {
      border-bottom: 1px solid #dddde5;
    }
  }

  .accordion-container {
    max-width: 1248px;
    margin: 0 auto;
    padding: 40px 118px;

    @media (min-width: 48rem) {
      padding: 40px 118px;
    }
  }

  .accordion-item {
    :hover {
      cursor: pointer;
    }

    :hover h3 {
      color: #9a7df4;
      transition: color 63ms ease-out;
    }
  }

  .accordion-button-container {
    padding: 40px 0;
  }

  .reset-button {
    background-color: transparent;
    border-width: 0;
    font-family: inherit;
    font-size: inherit;
    font-style: inherit;
    font-weight: inherit;
    line-height: inherit;
    padding: 0;
  }

  .accordion-button {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    font-size: 24px;
  }

  .accordion-heading {
    margin-top: 0px;
    margin-bottom: 0px;
    font-weight: 400;
    font-size: 24px;
    line-height: 1.6;
    text-align: left;

    @media all and (max-width: 60rem) {
      font-size: 20px;
    }
  }

  .accordion-icon {
    color: #9a7df4;
  }

  .accordion-content,
  dd {
    overflow: hidden;
    max-height: 1000px;
    transition: max-height 0.5s ease-out;
  }

  .accordion-content[aria-expanded="true"] {
    max-height: 0px;
    transition: max-height 0.25s ease-out;
  }

  .accordion-list {
    list-style: none;
    margin-top: 0px;
    margin-bottom: 32px;
    padding-left: 0px;
    margin-left: 20px;
  }

  .accordion-list-item {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
    line-height: 1.5;
    padding-bottom: 24px;

    .accordion-list-link {
      color: #9697a6 !important;
      font-size: 20px;
      text-transform: capitalize;
      font-weight: 600;

      @media all and (max-width: 60rem) {
        font-size: 16px;
      }

      &:hover {
        text-decoration: none !important;
      }
    }

    .accordion-list-text {
      display: none;
      color: #9697a6 !important;
      font-size: 20px;
      font-weight: 600;
      margin-top: 0;
      margin-bottom: 0;

      @media all and (max-width: 60rem) {
        font-size: 16px;
      }
    }

    &:hover {
      cursor: pointer;

      .accordion-list-link {
        color: #9a7df4 !important;
        transition: color 63ms ease-out;
      }

      .accordion-list-text {
        display: block;
        color: #9a7df4 !important;
        transition: color 63ms ease-out;
      }
    }
  }

  .accordion-header__icon {
    border-radius: 50%;
    height: 1rem;
    width: 1rem;
    margin-left: auto;
    padding: 1rem;
    position: relative;
  }

  .accordion-header__icon:before,
  .accordion-header__icon:after {
    content: " ";
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 1.125rem;
    height: 0.125rem;
    background-color: #9a7df4;
    transition: transform 0.1s ease-out, background-color 0.1s ease-out;
  }

  .accordion-header__icon:before {
    transform: translate(-50%, -50%) rotate(-90deg);
  }

  .accordion-header__icon:after {
    transform: translate(-50%, -50%) rotate(-180deg);
  }

  .accordion-header__icon[aria-expanded="false"]:before,
  .accordion-header__icon[aria-expanded="false"]:after {
    transform: translate(-50%, -50%) rotate(0deg);
  }
}
