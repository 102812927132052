@import "/src/shared-styles/variables/general.scss";

.navlink {
  width: 100%;
  cursor: pointer;
  &:hover {
    text-decoration: none;
  }
}
.sidebar {
  z-index: 1 !important;
  padding-top: 5rem;
}

.organizationName {
  font-size: 1.5rem;
  font-family: $font-family-rubik;
  font-weight: $font-weight-light;
  margin-bottom: 1.25rem;
}

.countColumn {
  display: flex;
  flex-direction: column;
}
.countRight {
  margin-left: 7.5rem;
}

.label {
  color: $color-grey-accent;
  font-family: $font-family-roboto;
  font-size: 0.75rem;

  &.blueLabel {
    color: $color-exclusive-blue;
  }

  &.greenLabel {
    color: $color-green;
  }

  &.redLabel {
    color: $color-brand;
  }
}
.countTitle {
  font-size: 0.875rem;
  font-weight: $font-weight-bold;
  color: $color-grey-accent;
}
.countInfo {
  font-size: 0.75rem;
  color: $color-org-count-grey;
  margin-right: 0.25rem;
}
.orgInfoPage {
  background-color: $color-white;
  padding: 2rem;
  font-family: $font-family-roboto;
}
.datasetInfo {
  padding: 1.5rem 2.5rem 3.5rem;
}
.datasetName {
  font-family: $font-family-rubik;
  font-size: 1.5rem;
  font-weight: $font-weight-light;
  text-transform: capitalize;
  color: $color-grey-dark;
  margin: 0 0 1.5rem;
}
.datasetPage,
.memberPage {
  background-color: $color-white;
  display: flex;
  align-items: stretch;
  height: 100vh;
  font-family: $font-family-roboto;
}
.apiPage {
  background-color: $color-white;
  padding: 3.5rem;
}

.apiDetailPage {
  background-color: $color-white;
  padding: 1.5rem;
  font-family: $font-family-roboto;
}
.backLink {
  font-size: 1.125rem;
  display: flex;
  align-items: center;
  &:hover {
    text-decoration: none;
  }
}
.backLinkIcon {
  font-weight: $font-weight-bold;
}
.backLinkText {
  margin-left: 1rem;
}
.apiDetailTitle {
  margin: 1rem 1.5rem 0 0;
  font-family: $font-family-rubik;
  font-size: 2rem;
  font-weight: $font-weight-light;
  line-height: 1.5rem;
}
.apiDetailHeadContainer {
  display: flex;
}
.apiDetailHeadContent {
  flex: 2;
}
.apiDetailHeadAction {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}
.apiDetailHeader {
  display: flex;
  align-items: flex-end;
  padding: 1.5rem 0;
}

.apiDetailCopyToken {
  width: 50%;
  padding: 0.625rem;
  text-align: initial;
  border: 1px solid $color-grey58;
  border-radius: 0 0.25rem 0.25rem 0;
  background: $org-db-api-box-background;
  font-family: $font-family-roboto;
  font-size: 0.875rem;
  font-weight: $font-weight-normal;
}

.apiTitle {
  line-height: 1.5;
  font-size: 1.5rem;
  font-family: $font-family-rubik;
  font-weight: $font-weight-light;
  padding-top: 0.25rem;
}
.apiFirstLine {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.25rem;
}
.apiHeaderRow {
  display: flex;
  border-bottom: 1px solid $color-org-db-color-grey-medium;
  font-family: $font-family-roboto;
  font-size: 0.75rem;
  padding-bottom: 0.375rem;
  font-weight: $font-weight-bold;
  line-height: 0.75rem;
}
.apiHeaderName {
  flex: 25;
  padding-left: 1rem;
}
.apiHeaderNumProduct {
  flex: 10;
  margin: auto 0;
  text-align: center;
}
.apiHeaderDateCreated {
  flex: 20;
  margin: auto 0;
  text-align: center;
}
.apiHeaderApiKey {
  flex: 45;
  margin: auto 0;
}
.apiKeyRow {
  display: flex;
  padding: 2rem 0;
  border-bottom: 1px solid $color-org-db-color-grey-medium;
  line-height: 1.25;
  color: $color-black;
  font-weight: $font-weight-normal;
  &:hover {
    background-color: $color-faint-grey;
    text-decoration: none;
    color: $color-black;
  }
}
.apiKeyName {
  flex: 25;
  padding-left: 1rem;
  margin: auto 0;
}
.apiKeyCount {
  flex: 10;
  margin: auto 0;
  text-align: center;
}
.apiKeyDate {
  flex: 20;
  margin: auto 0;
  text-align: center;
}
.apiKeyBox {
  flex: 45;
  display: flex;
}

.datasetList,
.memberList {
  width: 300px;
  overflow-y: auto;
  border-right: 1px solid $color-org-db-color-grey-medium;
}
.datasetItem {
  flex-direction: column;
  align-items: flex-start;
}

.memberItemName {
  font-size: 1rem;
  font-weight: $font-weight-bold;
  color: $color-black;
  margin: 0 0 0.5rem;
  display: flex;
  align-items: center;
}
.memberItemDesc {
  word-break: break-all;
  font-size: 0.875rem;
  font-weight: $font-weight-light;
  color: $color-black;
  margin: 0;
}
.memberItemInfo {
  display: flex;
  align-items: center;
}

.datasetItem,
.memberItem {
  min-height: 5rem;
  padding: 1rem;
  display: block;
  border-bottom: 1px solid $color-org-db-color-grey-medium;
  display: flex;
  align-items: center;

  &:hover {
    background-color: $color-pale-blue;
    text-decoration: none;
  }
  &.active {
    background-color: $color-pale-blue;
    text-decoration: none;
  }

  .productName {
    font-size: 1.125rem;
    line-height: 1.6;
    font-family: $font-family-rubik;
    font-weight: $font-weight-light;
    color: $color-grey-dark;
    word-break: break-all;
  }
}
.pending {
  margin: 0 0 0.125rem 0.375rem;
  padding: 0.125rem 0.25rem 0.125rem;
  border-radius: 2px;
  color: $color-grey-accent;
  border: 1px solid $color-grey-accent;
  font-size: 0.625rem;
  font-family: $font-family-roboto;
  font-weight: $font-weight-bold;
}
.pendingDay {
  margin-left: 0.25rem;
  color: $color-light-orange;
  font-size: 0.625rem;
  font-weight: $font-weight-bold;
  font-family: $font-family-roboto;
}

.sectionInfo {
  padding-bottom: 0.625rem;
  font-size: 0.75rem;
}
.sectionFlex {
  display: flex;
  padding-bottom: 1.25rem;
}
.sectionItem {
  width: 200px;
}
.sectionTitle {
  margin-bottom: 0.25rem;
  font-size: 0.75rem;
  font-weight: $font-weight-bold;
  text-transform: uppercase;
  display: inline-flex;
  align-items: center;
}
.infoIcon {
  margin-left: 0.25rem;
  color: $color-brand;
}
.listContainer {
  padding: 0 2.5rem;
}
.subHeaderContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.subHeader {
  color: $color-grey-dark;
  font-size: 1.25rem;
  font-weight: $font-weight-light;
  font-family: $font-family-rubik;
}
.userList {
  margin-top: 1rem;
}
.userRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.625rem;
  padding: 1rem;
  background-color: $color-white;
}
.status {
  font-family: $font-family-roboto;
  font-weight: $font-weight-bold;
  text-transform: uppercase;
  display: inline-block;
  margin-bottom: 0.375rem;
  padding: 0.25rem;
  color: $color-white;
  font-size: 0.625rem;
  text-align: center;
  border-radius: 2px;
  &.blue {
    background-color: $color-exclusive-blue;
  }
  &.green {
    background-color: $color-green;
  }
}
.inviteDesc {
  line-height: 1.22;
  font-size: 1.125rem;
  font-weight: $font-weight-light;
  font-family: $font-family-roboto;
  color: $color-welcome-banner-text-grey;
  margin: 0 0 1rem;
}
.inviteInput {
  width: 100%;
}

.datasetItem {
  flex-direction: column;
  align-items: flex-start;
}
.memberInfoContainer {
  display: flex;
  justify-content: space-between;
  padding: 1.25rem 1.5rem 1.875rem;
  font-family: $font-family-rubik;
  align-items: center;
}
.memberName {
  font-size: 1.5rem;
  font-weight: $font-weight-extra-light;
  margin-bottom: 1rem;
}
.memberButtonContainer {
  padding: 1.25rem;
  display: block;
  border-bottom: 1px solid $color-org-db-color-grey-medium;
}

.datasetContent,
.memberContent {
  flex: 1;
}
.emptyStateTitle {
  color: $color-title;
  font-size: 1.125rem;
}

.listWrapper {
  padding: 1.5rem;
}
.listRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.25rem 1rem;
  border-bottom: 1px solid $color-grey-light;
}

// organization info
.tableContainer {
  margin-top: 3rem;
}
.tableRow {
  display: flex;
}
.tableHeader {
  padding: 0.625rem 0;
  font-family: $font-family-quantico;
  font-size: 0.625rem;
  line-height: 1.3em;
  text-transform: uppercase;
  font-weight: $font-weight-bold;
  color: $color-green-grey;
}
.tableCard {
  height: 5.125rem;
  margin-bottom: 1.125rem;
  background-color: $color-white;
  border: 0.8px solid $color-grey-light;
  border-radius: 3px;
  box-shadow: 0 15px 12px 0 rgba(27, 27, 27, 0.22);
}
.tableName {
  font-family: $font-family-rubik;
  font-size: 1rem;
  font-weight: $font-weight-light;
  line-height: 1.47;
  &:hover {
    text-decoration: none;
  }
  &__blue {
    color: $color-exclusive-blue;
  }
}
.tableText {
  padding-left: 0.25rem;
}

.flex-2 {
  flex: 2 2 0%;
  padding: 0 0.75rem;
  font-family: $font-family-source-sans-pro;
  font-size: 0.75rem;
  color: $color-off-black;
}
.flex-1 {
  flex: 1 1 0%;
  padding: 0 0.75rem;
  font-family: $font-family-source-sans-pro;
  font-size: 0.75rem;
  color: $color-off-black;
}
.flex-1-5 {
  flex: 1.5 1.5 0%;
  padding: 0 0.75rem;
  font-family: $font-family-source-sans-pro;
  font-size: 0.75rem;
  color: $color-off-black;
}
.tooltipContent {
  border-radius: 4px;
  padding: 7px;
  font-size: 15px;
  line-height: 1;
  color: $color-grey-dark;
  font-size: 0.75rem;
  font-weight: $font-weight-light;
  background-color: white;
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
    hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  user-select: none;
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;
  width: 300px;
  background-color: $color-grey-light;
}
.tooltipContent[data-state="delayed-open"][data-side="top"] {
  animation-name: slideDownAndFade;
}
.tooltipContent[data-state="delayed-open"][data-side="right"] {
  animation-name: slideLeftAndFade;
}
.tooltipContent[data-state="delayed-open"][data-side="bottom"] {
  animation-name: slideUpAndFade;
}
.tooltipContent[data-state="delayed-open"][data-side="left"] {
  animation-name: slideRightAndFade;
}

@keyframes slideUpAndFade {
  from {
    opacity: 0;
    transform: translateY(2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideRightAndFade {
  from {
    opacity: 0;
    transform: translateX(-2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideDownAndFade {
  from {
    opacity: 0;
    transform: translateY(-2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideLeftAndFade {
  from {
    opacity: 0;
    transform: translateX(2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.copyIcon {
  height: 1.5rem;
  padding: 0.5rem;
  border: 1px solid $org-db-copy-green;
  border-right: 0;
  border-radius: 0.25rem 0 0 0.25rem;
  cursor: pointer;
}

.copyToken {
  width: 100%;
  padding: 0.625rem;
  text-align: initial;
  border: 1px solid $color-grey58;
  border-radius: 0 0.25rem 0.25rem 0;
  background: $org-db-api-box-background;
  font-family: $font-family-roboto;
  font-size: 0.875rem;
  font-weight: $font-weight-normal;
}

.apiDetailPermissionControl {
  display: flex;
  width: 70vw;
}

.apiDetailProductList {
  flex: 3;
  height: 400px;
  margin-right: 0.5rem;
  overflow-y: auto;
  border: 1px solid $color-floating-icon-grey;
  border-radius: 0.5rem;
  background-color: $color-white;
}
.apiDetailProductItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $color-floating-icon-grey;
}
.apiDetailEntitlementList {
  display: flex;
  flex: 40%;
  flex-direction: column;
  max-width: 40%;
  height: 400px;
  margin-left: 0.5rem;
  border: 1px solid $color-floating-icon-grey;
  border-radius: 0.5rem;
  background: $color-white;
}
.apiDetailProductText {
  padding: 0.75rem 0 1rem 0.75rem;
}

.accessCheck {
  color: $org-db-copy-green;
}
.accessIcon {
  height: 1.25rem;
  width: 1.25rem;
}

.entitlementBody {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1rem 1.25rem;
  overflow-y: auto;
}
.entitlementTitle {
  font-weight: $font-weight-bold;
}
.entitlementItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
  border-radius: 1rem;
  background-color: $color-grey82;
  font-size: 0.75rem;
  font-weight: $font-weight-normal;
}

.entitlementItemText {
  flex: 1;
  padding: 0.25rem 0.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: $font-weight-medium;
}
.entitlementCloseIcon {
  width: 0.75rem;
  height: 0.75rem;
  color: $color-black;
}

.noAccess {
  padding-top: 2rem;
}
.noAccessTitle {
  font-family: $font-family-quantico;
}
