@import "/src/shared-styles/variables/general.scss";

.container {
  font-family: $font-family-quantico;
  text-align: center;
  text-transform: uppercase;
  padding: 20px;
  margin: auto;
}

.wait {
  margin: 10px 0;
  font-size: 18px;
}

.hint {
  font-size: 22px;
  font-weight: $font-weight-bold;
}