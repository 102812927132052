.stat__graphic-wrapper {
  .graphic-text-stat-fact-row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 1440px;
    padding: 0 118px;

    @media only screen and (min-width: 48em) and (max-width: 64.68em) {
      max-width: 672px;
    }

    @media only screen and (max-width: 47.999em) {
      display: block;
      padding: 0;
      max-width: 346px;
    }
  }

  .graphic-text-stat-fact-row--split {
    @media only screen and (min-width: 48em) and (max-width: 64.68em) {
      display: block;
    }

    @media only screen and (max-width: 47.999em) {
      max-width: initial;
      margin: 0 0 55px;
    }
  }

  .graphic-text-stat {
    @media only screen and (min-width: 48em) and (max-width: 64.68em) {
      width: 100%;
    }

    @media only screen and (max-width: 47.999em) {
      max-width: 100vw;
    }
  }

  .graphic-text-stat-header {
    font-size: 36px;
    font-weight: 300;
    margin-top: 0px;
    margin-bottom: 20px;
    line-height: 1.4;

    @media only screen and (max-width: 47.999em) {
      font-size: 28px;
    }
  }

  .graphic-text-stat-body {
    font-size: 19px;
    font-weight: 300;
    line-height: 1.6;
    margin-top: 0px;
    margin-bottom: 0px;

    @media only screen and (max-width: 47.999em) {
      font-size: 18px;
    }
  }

  .graphic-text-stat-overlapping-items {
    position: relative;
    width: 660px;
    height: 520px;
    margin-right: 40px;

    @media only screen and (max-width: 47.999em) {
      width: auto;
      height: initial;
      margin-bottom: 80px;
      margin-right: 0;
    }
  }

  .graphic-text-stat-image-item {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: 90%;

    @media only screen and (max-width: 47.999em) {
      position: relative;
      width: 100%;
    }
  }

  .graphic-text-stat-text-item {
    position: absolute;
    z-index: 1;
    right: 0;
    bottom: 20px;
    width: 80%;
    padding: 38px 60px 64px 40px;
    color: #fff;
    right: -30px;

    @media only screen and (min-width: 48em) and (max-width: 64.68em) {
      top: 200px;
      right: -20px;
    }

    @media only screen and (max-width: 47.999em) {
      position: relative;
      width: auto;
      right: 0;
      top: -5px;
    }
  }

  .graphic-text-stat-background__blue_purple {
    background-image: linear-gradient(112deg, #31c6ca, #692a99 98%);
  }

  .graphic-text-stat-background__red_purple {
    background-image: linear-gradient(115deg, #dd591d, #9922ae);
  }

  .graphic-text-stat-data-wrapper {
    text-align: center;

    @media only screen and (min-width: 48em) and (max-width: 64.68em) {
      display: inline-block;
      margin-top: 25px;
      margin-left: 11px;
      text-align: left;
    }
  }

  .graphic-text-stat-hero-data {
    color: #1e90ba;
    font-size: 175px;
    font-weight: 100;
    line-height: 0.9;
    margin-top: 0px;
    margin-bottom: 0px;

    @media only screen and (max-width: 47.999em) {
      margin: 0 18px;
      font-size: 165px;
    }
  }

  .graphic-text-stat-data-sub-item {
    font-size: 18px;
    font-weight: 300;
    margin-top: 15px;
    margin-bottom: 0px;
    line-height: 1.2;

    @media only screen and (max-width: 47.999em) {
      margin: 15px 50px 0;
    }

    @media only screen and (min-width: 48em) and (max-width: 64.68em) {
      text-align: center;
      padding-bottom: 50px;
    }
  }
}
