.cta-link-api-backround {
  display: block !important;
  padding: 0 118px;
  margin-top: 120px;

  @media only screen and (max-width: 47.999em) {
    padding: 0 40px;
  }
}

.featuresgrid__section-wrapper + .cta-link-api-backround {
  margin-top: 0px;
}

.cta__link-background__blue_purple {
  background: linear-gradient(102deg, #1c818c, #501b73 100%);
}

.cta__link-background__green_blue {
  background: linear-gradient(100deg, #6db43e -1%, #00529a 100%, #00529a 100%);
}

.cta-link-create-header,
.cta-link-sign-up-title {
  color: #fff;
  text-transform: capitalize;
}

.cta-link-create-header {
  padding-top: 2.5rem;
  font-size: 18px;
  font-weight: 300;
  margin-bottom: 5px;
  margin-top: 0;
  padding-top: 41px;
}

.cta-link-sign-up-title {
  font-size: 40px;
  font-weight: 300;
  margin-top: 0;
  margin-bottom: 0;
  line-height: 1.4;

  @media only screen and (max-width: 47.999em) {
    width: 95%;
    font-size: 32px;
  }
}

.cta-link-footer-text,
.cta-link-sign-up {
  color: #fff;
}

.cta-link-footer-text {
  width: 75%;
  font-size: 18px;
  font-weight: 300;
  line-height: 1.6;
  margin-bottom: 50px !important;
  margin-top: 0;

  @media only screen and (max-width: 47.999em) {
    width: 95%;
  }
}

.cta-link-sign-up {
  margin-bottom: 0;
  padding-bottom: 41px;
  font-size: 16px;
  font-weight: 400;
}

.button__arrow--right {
  margin-left: 5px;
}
