@import '/src/shared-styles/variables/general.scss';

.register-success-modal {
  display: flex;
  flex-direction: column;
  align-items: center;

  .welcome-header {
    margin-top: -10px;
    margin-bottom: 20px;
    font-size: 30px;
    font-weight: 400;
    font-family: $font-family-rubik;
    line-height: 1.2;
    text-align: center;
    padding: 0 15px;
  }

  .api-header {
    margin-top: 0;
    text-transform: uppercase;
  }

  .api-key {
    margin-top: 0;
    margin-bottom: 5px;
    color: $color-green;
    font-size: 24px;
    font-family: $font-family-roboto;
    font-weight: 600;
  }

  .api-description {
    text-align: center;
    font-family: $font-family-roboto;
    margin-bottom: 0;
    line-height: 1.4;
  }
}
