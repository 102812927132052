@import "/src/shared-styles/variables/general.scss";

.alerts {
  padding: 10px 20px;
  transition-duration: 0.6s;
  color: $color-white;

  p {
    margin: 0;
    text-align: center;
    font-family: $font-family-rubik;
    font-weight: 500;
    font-size: 1.15rem;
    line-height: 20px;
  }

  &.info {
    background: #0e99b9;

    a {
      color: #0ff;
    }
  }

  &.success {
    background: #00a453;
  }

  &.error {
    background: #c43c24;
  }

  &.nasdaq, &.nasdaqblue {
    background-blend-mode: hard-light;
    background-image: linear-gradient(to right, #6db43e, #00529a);

    p {
      padding: 10px;

      .podge-anncouncement__short-ndl {
        display: none;
      }

      p {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        .podge-anncouncement__say-hello {
          margin-left: 5px;
        }
      }

    }
    .close {
      top: 23px
    }

    .podge-anncouncement{
      &__nasdaq-button {
        margin-left: 20px;
        padding: 1px 5px;
        background: $color-white;
        color: $color-grey-extra-dark;
        border-radius: 1.5px;
        text-decoration: none;
        font-size: 10px;
        font-weight: $font-weight-bold;
        font-family: $font-family-roboto;
      }
    }

    p {
      font-family: $font-family-rubik;
      font-weight: $font-weight-medium;
      font-size: 16px;
      color: $color-white;
    }

    a {
      color: $color-white;
      text-decoration: underline;
    }

    @media #{$media-medium} {
      .alert-content {
        .podge-anncouncement__message-text {
          flex-direction: column;
        }
        p {
          align-items: flex-start;
          justify-content: flex-start;
          .podge-anncouncement__say-hello {
            margin-left: 0;
          }
        }
      }

      .podge-anncouncement{
        &__looking-quandl {
          font-size: 18px;
          margin-bottom: 3px;
        }
        &__say-hello {
          font-size: 14px;
        }
        &__nasdaq-button {
          align-self: center;
        }
      }
    }

    @media #{$media-small} {
      .alert-content {
        .podge-anncouncement{
          &__nasdaq-button {
            align-self: center;
          }
          &__message-text {
            flex-direction: column;
            font-size: 16;
          }
        }
        p {
          align-items: flex-start;
          justify-content: flex-start;
          .podge-anncouncement__short-ndl {
            display: block;
            font-size: 12px;
          }

          .podge-anncouncement__say-hello {
            display: none;
          }
        }
      }
    }
  }

  &.nasdaqblue {
    background-blend-mode: hard-light;
    background-image: linear-gradient(92deg, #31c6ca, #692a99 98%);

    p {
      padding: 2px;
    }

    .close {
      top: 16px
    }
  }

  &.password-expiring {
    background-image: linear-gradient(to right, #ad2020, #fd5e5e);
    color: $color-white;

    a {
      color: $color-white;
    }

    p {
      font-family: $font-family-rubik;
      font-size: 14px;
      font-weight: 500;
    }

    div {
      font-size: 18px;
    }
  }
}

.close-button {
  width: 20px;
  height: 20px;
  float: right;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: 0;
  cursor: pointer;

  i {
    color: $color-white;
    font-size: 1.15rem;
  }
}
