@import "/src/shared-styles/variables/general.scss";

.trigger {
  background-color: $color-white;
  box-sizing: border-box;
  color: $color-top-bar-brown;
  text-decoration: none;
  font-weight: $font-weight-medium;
  height: 100%;
  padding: 14px 20px;
  white-space: nowrap;
  display: flex;
  position: relative;
  border: 0;

  &:hover {
    background-color: $color-faint-grey;
  }
}

.dropdown-content {
  z-index: 20;
  flex-direction: column;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.1);
  &[data-state="closed"],
  &--closed {
    display: none;
  }
  &[data-state="open"],
  &--open {
    display: flex;
    position: absolute;
    top: 45px;
    right: 0;
    pointer-events: all !important;
  }
}

.menu-item {
  color: $color-top-bar-brown;
  font-family: $font-family-roboto;
  font-weight: $font-weight-light;
  font-size: 13px;
  white-space: nowrap;
  text-transform: uppercase;
  cursor: pointer;
  text-decoration: none;
  padding: 14px;
  background-color: $color-white;

  a {
    color: inherit;
    font-weight: inherit;
  }

  &--button {
    border: 0;
    outline: none;
    color: inherit;
    font-family: inherit;
    font-weight: 300;
    text-transform: uppercase;
    text-align: left;
  }

  &:hover {
    color: $color-top-bar-brown;
    text-decoration: none;
    background-color: $color-faint-grey;

    a {
      color: inherit;
      font-weight: inherit;
    }
  }
}
