@import "/src/shared-styles/variables/general.scss";

.button {
  position: absolute;
  top: 8px;
  right: 10px;
  color: $color-grey-accent;
  font-size: 18px;
  cursor: pointer;
}
