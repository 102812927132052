.accept-tou-wrapper {
  display: flex;
  flex-direction: column;

  .greeting {
    margin-bottom: 0px;
    text-align: center;
  }

  .modal-message {
    margin-bottom: 0;
    text-align: center;
  }

  .terms-and-conditions-link {
    border-bottom: 1px solid;
    font-weight: 800;
  }
}
