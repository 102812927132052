.tableGroups__wrapper {
  scroll-behavior: smooth;
  width: 80%;
  @media only screen and (min-width: 770px) {
    margin: 0 auto 0 8.5rem;
  }

  @media only screen and (max-width: 770px) {
    margin: auto
  }
  
  .tableGroups__filter {
    display: flex;
    gap: 2rem;
    color: #6E6E6E;
    padding-bottom: 2rem;
    
    h3 {
      font-weight: 500;
      font-size: 14px;
    }

    .filter-dropdown {
      width: 50%;
    
      .filter::backdrop {
        padding-right: 2rem;
      }
    }
  }

  .tableGroups__pagination {
    width: 100%;
    text-align: center;
    border-top: 1px solid #E6E6E6;
    border-bottom: 1px solid black;
    padding: 1.25rem 0;
  }
}