@import "/src/shared-styles/variables/general.scss";

.content {
  display: inline-flex;
  align-items: center;
  list-style: none;
  margin: 0 20px;
  
  &--no-margin {
    margin: 0;
  }
}

.menuList {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  align-items: center;
}

.brand {
  display: flex;
  align-items: center;

  &:hover {
    text-decoration: none;
  }
}

.link {
  color: $color-top-bar-brown;
  font-weight: $font-weight-light;
  font-size: 13px;
  white-space: nowrap;
  text-transform: uppercase;
  cursor: pointer;
  text-decoration: none;
  padding: 14px;
  background-color: $color-white;

  &:hover {
    color: $color-top-bar-brown;
    text-decoration: none;
    background-color: $color-faint-grey;
  }

  &--list-item {
    padding-left: 20px;
    border: 0;
    font-family: $font-family-roboto;
    width: 100%;
    text-align: initial;

    &:hover {
      color: $color-top-bar-brown;
      text-decoration: none;
      background-color: $color-faint-grey;
    }
  }

  &--brand {
    color: $color-grey;
    font-size: 16px;
    text-transform: capitalize;
    margin-right: 70px;
    padding: 0px 14px;

    &:hover {
      color: $color-grey;
      background-color: $color-white;
    }
  }
}

.item {
  background-color: $color-white;
  box-sizing: border-box;
  color: $color-top-bar-brown;
  text-decoration: none;
  font-weight: $font-weight-medium;
  height: 100%;
  padding: 14px 20px;
  white-space: nowrap;
  display: flex;
  position: relative;
  border: 0;

  &:hover {
    background-color: $color-faint-grey;
  }
}

.container {
  background-color: $color-white;
  position: sticky;
  top: 0;
  z-index: 1000;
  display: flex;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.1);
  justify-content: space-between;
  transition: 300ms;

  &.hidden {
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
  }
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $color-black;
  font-family: $font-family-roboto;
  font-size: 13px;
  text-transform: uppercase;
  height: 26px;
  margin: 14px;
  padding: 0 14px;
  border: 1px solid;
  border-radius: 3px;
  font-weight: $font-weight-bold;
  white-space: nowrap;
  background-color: $color-white;

  &:hover {
    text-decoration: none;
    color: $color-black;
  }
}

.dropdown-content {
  z-index: 20;
  flex-direction: column;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.1);

  &[data-state="closed"],
  &--closed {
    display: none;
  }

  &[data-state="open"],
  &--open {
    display: flex;
    position: absolute;
    top: 45px;
    right: 0;
  }
}

.search-container {
  display: inline-flex;
  padding: 0.375rem 0.15rem;
  gap: 0.1rem;

  svg{
    color: rgb(29, 30, 37) !important;
  }

  form:focus-within {
    box-shadow: none !important; 
    outline: 1px dotted #212121;
    outline: 5px auto -webkit-focus-ring-color;
  }

  button{
    outline: none;
  }

  @media only screen and (max-width: 47.999em) {
    display: none;
  }
}

.search-bar {
  display: flex;
}

.application-header {
  position: static;
  background-color: $color-white;
}
