@import "/src/shared-styles/variables/general.scss";

.modal-content {
  padding: 0 20px 25px;
  .contact-sales-modal-heading-container {
    .contact-sales-modal-heading {
      margin-bottom: 0;
      color: #1b1b1b;
      font-family: $font-family-roboto;
      font-size: 24px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      letter-spacing: -0.24px;
      margin-top: 0;
      text-align: left;
    }

    .contact-sales-modal-description {
      color: #000;
      font-family: $font-family-roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      margin-top: 10px;
      margin-bottom: 20px;
      text-align: left;
    }
  }

  .contact-sales-modal-form-container {
    .error-message {
      font-size: 12px;
      font-family: $font-family-roboto;
      line-height: 16px;
      color: #e03d2e;
      margin-top: 0;
      margin-bottom: 5px;
      text-align: left;
      font-weight: 400;
      text-transform: uppercase;
    }
  }
}
