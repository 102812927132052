@import "/src/shared-styles/variables/general.scss";

.contentful-button__action {
  display: inline-block;
  .action_button {
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 700;

    &:hover {
      cursor: pointer;
    }
  }

  .action_button__orange_white {
    background-color: #e1701e;
    color: white;
    padding: 10px 20px;
    border: 1px solid #e1701e;
    margin-right: 20px;
    border-radius: 0.25rem;

    &:hover {
      background-color: #e1701e;
      color: white;
      border: 1px solid #e1701e;
      text-decoration: none;
    }
  }

  .action_button__blue_white {
    background-color: #0090ba;
    color: white;
    padding: 10px 20px;
    border: 1px solid #0090ba;
    border-radius: 0.25rem;

    &:hover {
      text-decoration: none;
      background-color: #00bfd5;
      color: white;
      border: 1px solid #00bfd5;
    }
  }

  .action_button__white-border {
    background-color: white;
    color: black;
    padding: 10px 20px;
    border-radius: 0.25rem;
    transition: all 0.075s linear, visibility 0s;
    border: 1px solid black;

    &:hover {
      text-decoration: none;
      background-color: black;
      color: white;
    }
  }

  .action_button__transparent {
    background-color: transparent;
    color: white;
    padding: 10px 20px;
    border-radius: 0.25rem;
    transition: all 0.075s linear, visibility 0s;
    border: 1px solid white;

    &:hover {
      text-decoration: none;
      background-color: white;
      color: black;
    }
  }

  .action_button__yellow {
    background-color: #fdb913;
    color: black;
    padding: 10px 20px;
    border-radius: 0.25rem;
    transition: all 0.075s linear, visibility 0s;

    &:hover {
      text-decoration: none;
      background-color: black;
      color: #fdb913;
    }
  }

  .typeform-button {
    width: 90%;
    margin-right: 20px;
  }
}
