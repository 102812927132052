@import "/src/shared-styles/variables/general.scss";

.privacy__container {
  display: flex;
  position: fixed;
  z-index: 9999;
  bottom: 20px;
  left: 50%;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 960px;
  padding: 18px 18px 20px 40px;
  transform: translate3d(-50%, 0, 0);
  border-radius: 100px;
  background: $color-black-transparent;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);

  a {
    color: $color-white;
    text-decoration: underline;
    cursor: pointer;
  }

  p {
    font-size: 18px;
    line-height: 1.1;
  }

  @media #{$media-small}, #{$media-medium} {
    right: 10px;
    bottom: 10px;
    left: 10px;
    flex-wrap: wrap;
    width: auto;
    padding: 14px 15px;
    transform: none;
    border-radius: 10px;
  }
}

.privacy__heading {
  margin-top: 0px;
  margin-bottom: 3px;
  color: $color-white;
  font-family: $font-family-roboto;
  font-size: 16px;
  font-weight: $font-weight-medium;
  line-height: 22px;
  text-transform: uppercase;
}

.privacy__content {
  margin-top: 0px;
  margin-right: 20px;
  margin-bottom: 3px;
  color: $color-white;
  font-family: $font-family-roboto;
  font-size: 16px;
  font-weight: $font-weight-light;
  line-height: 22px;
}

.privacy__button {
  margin-left: 5px;
  border-radius: 5px;
  background-color: $color-blue-footer-links;
  color: $color-white;
  font-family: $font-family-roboto;
  font-size: 16px;
  font-weight: $font-weight-bold;
  text-transform: uppercase;

  @media #{$media-small} {
    margin-top: 10px;
  }
}
