.accordion-richtext-wrapper {
  margin-bottom: 120px;
  .dark-background {
    background-color: #08062a;

    .accordion-rich-text-heading {
      color: white;
    }

    .accordion-left {
      border-bottom: 1px solid #282742;

      &:first-child {
        border-top: 1px solid #282742;
      }
    }

    .accordion-right {
      border-top: 1px solid #282742;

      &:last-child {
        border-bottom: 1px solid #282742;
      }
    }
  }

  .light-background {
    background-color: #f5f5fa;

    .accordion-rich-text-heading {
      color: #09062a;
    }

    .accordion-left {
      border-bottom: 1px solid #dddde5;

      &:first-child {
        border-top: 1px solid #dddde5;
      }
    }

    .accordion-right {
      border-top: 1px solid #dddde5;

      &:last-child {
        border-bottom: 1px solid #dddde5;
      }
    }

    p,
    a {
      color: #08062a;
    }
  }

  .accordion-rich-text-container {
    max-width: 1248px;
    margin: 0 auto;
    padding: 16px 118px;

    @media (min-width: 48rem) {
      padding: 16px 118px;
    }
  }

  .accordion-rich-text-border {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0 24px;

    @media all and (max-width: 47rem) {
      display: block;
    }
  }

  .grid.left {
    grid-column: 1 / span 1;
    grid-auto-rows: min-content;
  }

  .grid.right {
    grid-column: span 1 / -1;
    grid-auto-rows: min-content;
  }

  .accordion-rich-text-item {
    :hover {
      cursor: pointer;
    }

    :hover h3 {
      color: #9a7df4;
      transition: color 63ms ease-out;
    }
  }

  .reset-button {
    background-color: transparent;
    border-width: 0;
    font-family: inherit;
    font-size: inherit;
    font-style: inherit;
    font-weight: inherit;
    line-height: inherit;
    padding: 0;
  }

  .accordion-button {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    font-size: 24px;

    @media all and (max-width: 47rem) {
      font-size: 20px;
    }
  }

  .accordion-rich-text-heading {
    margin-top: 0px;
    margin-bottom: 0px;
    font-weight: 400;
    font-size: 24px;
    line-height: 1.6;
    text-align: left;

    @media all and (max-width: 60rem) {
      font-size: 20px;
    }
  }

  .accordion-rich-text-button-container {
    padding: 24px 0;
  }

  .accordion-rich-text-content {
    margin-left: 0;
    overflow: hidden;
    max-height: 1000px;
    transition: max-height 0.25s ease-out;

    p {
      margin-top: 24px;
      font-size: 20px;
      letter-spacing: -0.02rem;
      line-height: 2.2rem;
      font-weight: 400;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        padding-bottom: 32px;
        margin-bottom: 0;
      }

      @media all and (max-width: 47rem) {
        font-size: 16px;
        letter-spacing: 0.02rem;
        line-height: 1.6rem;
      }
    }

    a {
      text-transform: none;
      text-decoration: underline !important;
      font-weight: 400;
    }

    ul {
      padding-left: 15px;
    }
  }

  .accordion-rich-text-content[aria-expanded="true"] {
    max-height: 0px;
    transition: max-height 0.25s ease-out;
  }

  .accordion-rich-text-header__icon {
    border-radius: 50%;
    height: 1rem;
    width: 1rem;
    margin-left: auto;
    padding: 1rem;
    position: relative;
  }

  .accordion-rich-text-header__icon:before,
  .accordion-rich-text-header__icon:after {
    content: " ";
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 1.125rem;
    height: 0.125rem;
    background-color: #9a7df4;
    transition: transform 0.1s ease-out, background-color 0.1s ease-out;
  }

  .accordion-rich-text-header__icon:before {
    transform: translate(-50%, -50%) rotate(-90deg);
  }

  .accordion-rich-text-header__icon:after {
    transform: translate(-50%, -50%) rotate(-180deg);
  }

  .accordion-rich-text-header__icon[aria-expanded="false"]:before,
  .accordion-rich-text-header__icon[aria-expanded="false"]:after {
    transform: translate(-50%, -50%) rotate(0deg);
  }
}
