.sub-nav {
  display: block;
  position: relative;
  height: 50px;
  overflow: hidden;
  border: 1px solid #e6e6e6;
  background-color: white;
  z-index: 50;

  nav {
    display: inline-block;

    @media only screen and (max-width: 47.999em) {
      width: 100%;
      margin-top: 0;
      overflow: hidden;
    }
  }

  ul {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    list-style-type: none;
    margin-top: 0px;
    margin-bottom: 0px;

    @media only screen and (max-width: 47.999em) {
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;
    }
  }

  li {
    display: inline;
  }

  &__crumb {
    display: inline-block;
    padding: 0 280px 0 20px;
    color: #a7a9ac;
    font-size: 16px;
    line-height: 50px;

    @media only screen and (min-width: 48em) and (max-width: 64.68em) {
      padding: 0;
    }

    @media only screen and (max-width: 47.999em) {
      display: none;
    }
  }

  &__item {
    display: inline-block;
    margin: 0 20px;
    padding: 0 3px;
    border-width: 3px 0;
    border-style: solid;
    border-color: transparent;
    color: #a7a9ac;
    font-size: 14px;
    font-weight: 600;
    line-height: 44px;
    text-decoration: none;
    text-transform: uppercase;
  }

  &__item.active,
  &__item:active,
  &__item:focus,
  &__item:hover {
    color: #1b1b1b;
    text-decoration: none;
  }

  &__menu--sticky {
    @media only screen and (min-width: 48em) and (max-width: 64.68em) {
      min-width: initial;
      max-width: initial;
      padding: 0 49px;
    }
  }
}

