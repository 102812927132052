@import "/src/shared-styles/variables/general.scss";

.support-request {
  max-width: 800px;
  background: $color-white;
  padding: 38px;

  &__title {
    font-family: $font-family-rubik;
    font-size: 24px;
    font-weight: $font-weight-light;
    color: $color-grey-extra-dark;
    margin: 0;
  }

  &__subtitle {
    font-family: $font-family-roboto;
    font-size: 14px;
    font-weight: $font-weight-light;
    color: $color-grey-xx-dark;
  }

  &__content {
    display: flex;
    justify-content: space-between;
  }

  &__form {
    width: 55%;

    .error-message {
      font-size: 12px;
      font-family: $font-family-roboto;
      line-height: 16px;
      color: #e03d2e;
      margin-top: 0;
      margin-bottom: 5px;
      text-align: left;
      font-weight: 400;
      text-transform: uppercase;
    }
  }
}

.articles {
  padding: 0 20px;

  &__header {
    margin-bottom: 5px;
    margin-top: 0;
    color: $color-grey-extra-dark;
    font-family: $font-family-rubik;
    font-size: 16px;
    font-weight: $font-weight-bold;
  }

  &__content {
    list-style-type: none;
    padding-left: 0;
  }

  &__link {
    margin: 11px 0;
    color: $color-blue-grey-dark;
    font-family: $font-family-rasa;
    font-size: 16px;
    font-weight: $font-weight-light;

    a:hover {
      text-decoration: none;
    }
  }
}
