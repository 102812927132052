.background-rich-text-info-wrapper {
  max-width: 820px;
  margin: 0 118px 48px;

  @media only screen and (min-width: 1441px) {
    margin: 0 auto;
  }

  @media only screen and (min-width: 48em) and (max-width: 64.68em) {
    max-width: 672px;
  }

  @media only screen and (max-width: 47.999em) {
    max-width: 346px;
    margin: 0 auto;
  }

  .info-content {
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    margin-bottom: 80px;
    padding: 28px 25px 40px;

    @media only screen and (max-width: 47.999em) {
      text-align: center;
    }

    h2 {
      margin-bottom: 27px;
      color: #fff;
      font-size: 32px;
      font-weight: 300;
      line-height: 1.4;

      @media only screen and (max-width: 47.999em) {
        margin-top: 0;
        margin-bottom: 25px;
        font-size: 28px;
      }
    }

    p {
      color: #fff;
      margin-bottom: 16px;
      font-size: 18px;
      font-weight: 300;
      line-height: 1.22;

      p:last-of-type {
        margin-bottom: 0;
      }
    }

    ul,
    ol {
      margin-left: -20px;
      margin-bottom: 30px;

      li {
        color: white;
      }

      p {
        text-align: left;
      }
    }

    img {
      display: block;
      width: 450px;
      height: 255px;
      margin-bottom: 27px;
      background-size: contain;
      @media only screen and (max-width: 47.999em) {
        width: 296px;
        height: 168px;
        margin-bottom: 22px;
      }
    }
  }
}

.background-rich-text-fabric-wrapper {
  position: relative;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 455px;
  margin-top: 120px;

  @media only screen and (min-width: 48em) and (max-width: 64.68em) {
    min-height: 390px;
  }

  @media only screen and (max-width: 47.999em) {
    min-height: 556px;
  }

  .fabric-content {
    max-width: 1440px;
    padding: 77px 118px 67px;

    @media only screen and (min-width: 1441px) {
      margin: 0 auto;
    }

    @media only screen and (min-width: 48em) and (max-width: 64.68em) {
      max-width: 672px;
      margin: 0 auto;
    }

    @media only screen and (max-width: 47.999em) {
      max-width: 290px;
      margin: 0 auto;
    }
  }

  h2 {
    margin-top: 0;
    color: white;
    display: block;
    margin-bottom: 25px;
    font-size: 40px;
    font-weight: 300;
    line-height: 1.4;

    @media only screen and (min-width: 48em) and (max-width: 64.68em) {
      margin-bottom: 20px;
      font-size: 32px;
    }

    @media only screen and (max-width: 47.999em) {
      margin-bottom: 13px;
      font-size: 28px;
    }
  }

  p {
    color: white;
    max-width: 60%;
    margin-bottom: 25px;
    font-size: 19px;
    font-weight: 400;
    line-height: 1.68;

    @media only screen and (min-width: 48em) and (max-width: 64.68em) {
      max-width: initial;
      margin-bottom: 18px;
      font-size: 16px;
    }

    @media only screen and (max-width: 47.999em) {
      max-width: initial;
      margin-bottom: 18px;
      font-size: 16px;
    }
  }
}
