.slideshow-fabric-content {
  max-width: 1440px;
  padding: 0 118px;

  @media only screen and (min-width: 1441px) {
    margin: 0 auto;
  }

  @media only screen and (min-width: 48em) and (max-width: 64.68em) {
    max-width: 672px;
    margin: 0 auto;
  }

  @media only screen and (max-width: 47.999em) {
    padding-right: 0px;
    padding-left: 0px;
    margin: 0 auto;
    padding-top: 36px;
  }

  .slideshow__marketing-features {
    position: relative;

    @media only screen and (min-width: 48em) and (max-width: 64.68em) {
      margin-bottom: 83px;
    }

    @media only screen and (max-width: 47.999em) {
      height: initial;
      margin-bottom: 72px;
    }

    .slideshow__wrapper {
      width: 100%;
      max-width: 1440px;
      margin: 0 auto;

      @media only screen and (min-width: 81em) {
        padding-right: 0;
        padding-left: 0;
      }

      @media only screen and (min-width: 48em) and (max-width: 64.68em) {
        max-width: 672px;
      }

      @media only screen and (max-width: 47.999em) {
        max-width: 346px;
      }
    }

    .slideshow__features-wrapper {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: start;
      align-items: flex-start;
      -ms-flex-pack: justify;
      justify-content: space-between;

      @media only screen and (max-width: 47.999em) {
        padding-top: 0px;
      }
    }

    .slideshow__features-list {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-flow: wrap;
      flex-flow: wrap;
      -ms-flex-align: center;
      align-items: center;
      width: 50%;
      animation: fade-in 0.25s ease-in-out 0.25s;
      opacity: 0;
      animation-fill-mode: forwards;

      @media only screen and (max-width: 47.999em) {
        width: 100%;
      }
    }

    .slideshow__feature {
      width: 100%;
      min-height: 127px;
      margin-bottom: 10px;
      padding: 16px 20px 10px;
      transition: all 0.15s ease;
      background-size: 17px;
      cursor: pointer;
      border: 1px solid #d0d8df;
      background-color: #fff;
      box-sizing: border-box;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;

      @media screen and (min-width: 1120px) {
        width: 49%;
        max-width: 285px;

        &:nth-child(odd) {
          margin-right: 10px;
        }
      }
    }

    .slideshow__feature--active {
      transition: all 0.15s ease;
      border: 2px solid;
      border-image-source: linear-gradient(to right, #6db43e, #00529a);
      border-image-slice: 1;
      box-shadow: 0 0 10px 0 rgba(6, 64, 83, 0.1);

      p {
        color: #1e90ba;
      }

      &__icon {
        transition: all 0.15s ease;
        opacity: 1;
        -webkit-filter: none;
        filter: none;
      }

      img {
        opacity: 1;
        -webkit-filter: none;
        filter: none;
      }
    }

    .slideshow__feature__icon {
      display: inline-block;
      height: 34px;
      margin-bottom: 9px;
      transition: all 0.15s ease;
      opacity: 0.8;
      -webkit-filter: grayscale(100%) contrast(0);
      filter: grayscale(100%) contrast(0);
    }

    .slideshow__feature:hover .slideshow__feature__icon {
      opacity: 1;
      -webkit-filter: none;
      filter: none;
    }

    .slideshow__feature__title {
      margin-top: 0px;
      margin-bottom: 0px;
      transition: all 0.15s ease;
      color: #8698a1;
      font-size: 18px;
      line-height: 1.3;
    }

    .slideshow__feature:hover .slideshow__feature__title {
      color: #576f7b;
    }

    .slideshow__feature__body {
      display: none;

      @media only screen and (max-width: 47.999em) {
        margin-top: 20px;
        margin-bottom: 0px;
        transition: all 0.15s ease;
        color: #0d3244;
        font-size: 12px;
        font-weight: 400;
        line-height: 1.67;
      }
    }

    .slideshow__feature__active-body {
      display: none;

      @media only screen and (max-width: 47.999em) {
        display: block;
      }
    }

    .slideshow__active-feature-wrapper {
      display: -ms-flexbox;
      display: flex;
      position: relative;
      -ms-flex-align: stretch;
      align-items: stretch;
      width: 50%;
      min-height: 401px;
      margin-left: 10px;
      padding: 4px;
      @media only screen and (max-width: 47.999em) {
        display: none;
      }
    }

    .slideshow__active-feature {
      width: 100%;
      padding: 40px;
      background-image: linear-gradient(122deg, #6db43e, #00529a);
      box-shadow: 0 0 25px 0 rgba(6, 64, 83, 0.1);
      animation: fade-in 0.25s ease-in-out 1.5s;
      opacity: 0;
      animation-fill-mode: forwards;

      @media only screen and (max-width: 47.999em) {
        margin-left: 0;
      }

      p {
        font-size: 20px;
        font-weight: 400;
        line-height: 1.5;
      }

      &__header {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: start;
        align-items: flex-start;
        -ms-flex-pack: justify;
        justify-content: space-between;
        margin-top: 0px;
        margin-bottom: 16px;
        transition: all 0.15s ease;
        line-height: 1.4;
      }

      &__title {
        margin-top: 0px;
        margin-bottom: 0px;
        max-width: 60%;
        transition: all 0.15s ease;
        color: #fff;
        font-size: 40px;
        font-weight: 300;

        @media only screen and (min-width: 48em) and (max-width: 64.68em) {
          font-size: 24px;
        }
      }

      &__icon {
        height: 65px;
        transition: all 0.15s ease;
        filter: invert(1%) sepia(130%) saturate(1%) hue-rotate(108deg)
          brightness(150%) contrast(150%);
      }

      &__body {
        max-width: 90%;
        margin-top: 0px;
        margin-bottom: 20px;
        color: #fff;
        font-size: 18px;
        font-weight: 400;
        line-height: 1.5;
      }
    }
  }

  .slideshow__fadeInDown {
    animation: fadeInDown 250ms ease-out;
    opacity: 0;
    animation-fill-mode: forwards;
  }

  @keyframes fadeInDown {
    0% {
      opacity: 0;
      -webkit-transform: translate3d(0, -20%, 0);
      transform: translate3d(0, -20%, 0);
    }

    100% {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
      display: none;
    }
  }

  .slideshow__active-feature-wrapper[data-scroll-scene--trace-border="wrapper"]::before {
    content: "";
    visibility: hidden;
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border-top: 1px solid #d0d8df;
    border-left: 1px solid #d0d8df;
  }

  .slideshow__active-feature-wrapper[data-scroll-scene--trace-border="wrapper"]::before {
    animation: trace-border-top-left 0.25s ease-in-out forwards;
    animation-delay: 0.75s;
  }

  .slideshow__active-feature-wrapper[data-scroll-scene--trace-border="wrapper"]::after {
    content: "";
    visibility: hidden;
    position: absolute;
    z-index: 2;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-right: 1px solid #d0d8df;
    border-bottom: 1px solid #d0d8df;
  }

  .slideshow__active-feature-wrapper[data-scroll-scene--trace-border="wrapper"]::after {
    animation: trace-border-bottom-right 0.5s ease-in-out forwards;
    animation-delay: 0.75s;
  }

  @keyframes trace-border-bottom-right {
    0%,
    50% {
      visibility: hidden;
      width: 0;
      height: 0;
      padding-bottom: 0;
    }

    75% {
      visibility: visible;
      width: 100%;
      height: 0;
      padding-bottom: 0;
    }

    100% {
      visibility: visible;
      width: 100%;
      height: 100%;
      opacity: 1;
    }
  }

  @keyframes trace-border-top-left {
    0% {
      visibility: visible;
      width: 0;
      height: 0;
      padding-top: 0;
    }

    25% {
      visibility: visible;
      width: 100%;
      height: 0;
      padding-top: 0;
    }

    50% {
      visibility: visible;
      width: 100%;
      height: 100%;
    }

    100% {
      visibility: visible;
    }
  }
}
