@import '/src/shared-styles/variables/general.scss';
@import './animations';

.global-loading-bar {
  animation: globalLoadingProgress 1s ease-in infinite;
  position: fixed;
  top: 0;
  left: 0;
  height: 3px;
  border-radius: 0 1px 1px 0;
  background: $color-brand;
  z-index: 9999;
}
