.features-list__section-wrapper {
  position: relative;
  background-color: black;

  .black-green-theme {
    .card__header {
      color: #00eb80;
    }

    .card__body {
      color: #e6e6e6;
    }
  }

  .white-black-theme {
    .card__header {
      color: #1b1b1b;
    }

    .card__body {
      color: #1b1b1b;
    }
  }

  .section-content {
    display: -ms-flexbox;
    display: flex;
    position: relative;
    -ms-flex-pack: justify;
    justify-content: space-between;
    max-width: 1440px;
    margin: 0 auto;
    padding: 0 118px;

    @media only screen and (max-width: 47.999em) {
      max-width: 320px;
      margin: 0 auto;
      padding: 0;
    }

    @media only screen and (min-width: 48em) and (max-width: 64.68em) {
      max-width: 672px;
      margin: 0 auto;
      padding: 0;
    }

    @media only screen and (min-width: 64.68em) and (max-width: 1680px) {
    }

    @media only screen and (min-width: 1441px) {
      .section-content {
        margin: 0 auto;
        padding-bottom: 60px;
      }
    }

    .card__container {
      display: -ms-flexbox;
      display: flex;
      z-index: 2;
      -ms-flex-preferred-size: 100%;
      flex-basis: 100%;
      max-width: 560px;

      @media only screen and (max-width: 47.999em) {
        -ms-flex-direction: column;
        flex-direction: column;
        -ms-flex-align: center;
        align-items: center;
      }

      @media only screen and (min-width: 48em) and (max-width: 64.68em) {
        max-width: 350px;
      }

      @media only screen and (min-width: 1441px) {
        max-width: 600px;
      }
    }

    .card__icon {
      width: 50px;
      min-width: 50px;
      height: 50px;
      margin-right: 42px;

      @media only screen and (max-width: 47.999em) {
        margin: 0 auto 8px;
      }
    }

    @media only screen and (max-width: 47.999em) {
      .card__content {
        text-align: center;
      }
    }

    .card__header {
      font-size: 20px;
      font-weight: 600;
      line-height: 32px;
      margin-top: 0;

      @media only screen and (min-width: 48em) and (max-width: 64.68em) {
        margin-bottom: 10px;
        font-size: 16px;
        line-height: 20px;
      }

      @media only screen and (max-width: 47.999em) {
        margin-bottom: 8px;
        font-size: 16px;
        line-height: 20px;
      }
    }

    .card__body {
      margin-bottom: 45px;

      p {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
      }

      @media only screen and (min-width: 48em) and (max-width: 64.68em) {
        margin-bottom: 22px;
        line-height: 20px;
      }

      @media only screen and (max-width: 47.999em) {
        margin-bottom: 30px;
        font-size: 16px;
        line-height: 20px;
      }
    }
  }

  .overlapping-images-wrapper {
    position: absolute;
    right: 0;
    top: 0;

    @media only screen and (max-width: 47.999em) {
      display: none;
    }

    .overlapping-images {
      position: relative;
      width: 535px;
      height: 370px;

      @media only screen and (min-width: 48em) and (max-width: 64.68em) {
        width: 267px;
        height: 353px;
      }
    }

    .overlapping-image {
      position: absolute;
      top: 28px;
      right: 0;
      width: 356px;
      height: 356px;
      background-repeat: no-repeat;
      background-size: cover;

      @media only screen and (min-width: 48em) and (max-width: 64.68em) {
        width: 268px;
        height: 309px;
      }

      @media only screen and (min-width: 75em) {
        top: 42px;
        width: 500px;
        height: 500px;
      }
    }

    .colored-box {
      width: 223px;
      height: 429px;
      margin-left: auto;
      background-color: #5cb9d4;

      @media only screen and (min-width: 48em) and (max-width: 64.68em) {
        width: 135px;
        height: 353px;
      }

      @media only screen and (min-width: 75em) {
        width: 324px;
        height: 579px;
      }
    }
  }
}
