@import "/src/shared-styles/variables/general.scss";

.formItem {
  flex: 50%;
  box-sizing: border-box;
  padding: 0 0.75rem 0.5rem;
  &:nth-child(odd) {
    max-width: 50%;
  }
  @media only screen and (max-width: 47.999em) {
    flex: 100%;

    &:nth-child(odd) {
      max-width: 100%;
    }
  }
}
.formContainer {
  padding: 0.5rem 0;
}
.formItemContainer {
  display: flex;
  flex-wrap: wrap;
  padding: 0.5rem 0;
}
.formRow {
  flex: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.formHeaderContainer {
  padding: 0 0.75rem;
}

.formHead {
  color: $color-grey-dark;
  font-size: 1.875rem;
  line-height: 1em;
  font-weight: $font-weight-normal;
  font-family: $font-family-rubik;
  margin-bottom: 0;
}
.formHint {
  color: $color-bright-blue;
  font-size: 1.125rem;
  font-weight: $font-weight-normal;
  font-family: $font-family-rubik;
  padding-bottom: 1.875rem;
}
.formHeadSub {
  color: $color-grey-xx-dark;
  font: 0.875rem;
  font-family: font-family-roboto;
}
.formError {
  border: 1px solid #f9585f;
}
.formErrorMsg {
  display: block;
  margin-top: 0.25rem;
  color: #f8333c;
}
.formSubmit {
  padding: 0.5rem;
  width: 100%;
}

.oktaHint {
  padding: 0 0.75rem;
  font-family: $font-family-quantico;
}
.oktaHintHead {
  font-weight: $font-weight-bold;
}
.oktaHintSub {
  line-height: 1.625rem;
}
