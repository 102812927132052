.rich-text-media-description-video {
  max-width: 1440px;
  margin: auto;
  padding: 0 118px 0;

  @media only screen and (min-width: 48em) and (max-width: 64.68em) {
    padding: 10px 118px 0;
  }

  @media only screen and (max-width: 47.999em) {
    padding: 10px 40px 20px;
  }

  .rich-text-media-description-header {
    margin-bottom: 22px;
    color: #1b1b1b;
    font-size: 40px;
    font-weight: 300;
    line-height: 1.4;

    @media only screen and (max-width: 47.999em) {
      height: initial;
      font-size: 18px;
    }
  }

  .with-top-blue-line {
    position: relative;
    padding-top: 5px !important;

    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 30px;
      height: 1.5px;
      background-color: #0090ba;
    }
  }

  .rich-text-media-video-container {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: flex-start;
    justify-content: space-between;

    @media only screen and (min-width: 48em) and (max-width: 64.68em),
      only screen and (max-width: 47.999em) {
      flex-direction: column;
      align-items: center;
      width: 100%;
    }
  }

  .rich-text-media-video {
    margin-left: 38px;
    width: 47%;

    @media only screen and (min-width: 48em) and (max-width: 64.68em),
      only screen and (max-width: 47.999em) {
      margin: 0 0 38px;
      width: 100%;
    }

    img {
      max-width: 100%;
      height: auto;
    }
  }

  .rich-text-media-video-description {
    font-weight: 300;
    width: 47%;
    font-size: 18px;

    @media only screen and (min-width: 48em) and (max-width: 64.68em) {
      width: 580px;
    }

    @media only screen and (max-width: 47.999em) {
      width: 100%;
    }
    h3 {
      line-height: 1.4;
    }

    p {
      color: #1b1b1b;
      font-size: 18px;
      font-weight: 300;
      line-height: 1.6;

      @media only screen and (max-width: 47.999em) {
        margin-bottom: 30px;
        font-size: 14px;
      }
    }

    p:first-of-type {
      margin-top: 0;
    }

    ul,
    ol {
      margin-left: -20px;

      p {
        margin: 4px 0 10px;
      }
    }

    a {
      color: #0090ba;
      text-transform: lowercase;
      text-decoration: none;
      font-weight: 300;

      a:hover {
        text-decoration: none;
      }
    }
  }
}
