.featuresgrid__section-wrapper {
  position: relative;
  background-size: cover;
  width: 100%;
  background-repeat: no-repeat;
  padding-bottom: 120px;

  + .background-rich-text-fabric-wrapper {
    margin-top: 0;
  }

  + .short-structured-section-wrapper .short-structured-market-header-container {
    margin-top: 0;
  }

  + .graphic-text-stat-fact-row {
    margin-top: 0;
  }

  .section-content {
    display: -ms-flexbox;
    display: flex;
    position: relative;
    -ms-flex-pack: justify;
    justify-content: space-between;
    max-width: 1440px;
    padding: 0 118px;

    @media only screen and (min-width: 1441px) {
      margin: 0 auto;
    }

    @media only screen and (min-width: 48em) and (max-width: 64.68em) {
      max-width: 672px;
      margin: 0 auto;
      padding-bottom: 0;
    }

    @media only screen and (max-width: 47.999em) {
      max-width: 320px;
      margin: 0 auto;
      padding: 0;
    }
  }

  .feature__link{
    border: none;
    background: inherit;
    display: flex;
    gap: 0.25rem;
    cursor: pointer;
  }

  .feature__card__link, .feature__card__div {
    height: 400px;
  }

  .section-content--no-bottom-margin {
    margin-bottom: 0;
  }

  .product-boxes {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;

    @media only screen and (min-width: 1661px) {
      grid-template-columns: repeat(4, 1fr);
    }

    @media only screen and (min-width: 64.69em) and (max-width: 1661px) {
      grid-template-columns: repeat(3, 345px);
    }

    @media only screen and (min-width: 48em) and (max-width: 64.68em) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media only screen and (max-width: 47.999em) {
      grid-template-columns: 1fr;
    }

    .feature__link__wrapper {
      position: relative;
      display: flex;
      flex-direction: column;
      flex: 1;
      min-height: 310px;
      padding: 45px 30px;

      @media only screen and (max-width: 47.999em) {
        //height: 360px;
        margin-bottom: 35px;
        //min-height: auto;

        min-height: auto;
      }

      @media only screen and (min-width: 48em) and (max-width: 64.68em) {
        //height: 360px;
        margin-bottom: 37px;
        min-height: auto;
        padding: 30px 23px;
      }

      @media only screen and (min-width: 1441px) {
        padding: 45px;
      }
    }

    .feature__wrapper {
      padding: 45px 30px;

      @media only screen and (min-width: 48em) and (max-width: 64.68em) {
        padding: 30px 23px;
      }

      @media only screen and (min-width: 1441px) {
        padding: 45px;
      }
    }

    .feature__card-wrapper {
      height: 100%;
    }

    .feature__card__div {
      text-decoration: none;
      text-transform: none;
      max-width: 360px;
      flex-basis: 0;
      flex-grow: 1;
    }

    .feature__card__link {
      max-width: 360px;
      flex-basis: 0;
      flex-grow: 1;
      cursor: pointer;
      text-decoration: none;
      text-transform: none;
      &:hover {
        text-decoration: none;
        text-transform: none;
      }
    }

    .feature__status {
      margin-top: 0px;
      margin-bottom: 30px;
      font-size: 14px;
      text-transform: uppercase;

      @media only screen and (min-width: 48em) and (max-width: 64.68em) {
        margin-bottom: 20px;
        font-size: 12px;
        line-height: 32px;
      }
    }

    .feature__title {
      margin-top: 0px;
      margin-bottom: 20px;
      font-size: 24px;
      font-weight: 600;
      line-height: 28px;

      @media only screen and (min-width: 48em) and (max-width: 64.68em) {
        margin-bottom: 10px;
      }
    }

    .feature__icon {
      margin-bottom: 20px;
      max-width: 40%;

      &.square {
        height: 100px;
        width: 100px;  
      }
    }

    .feature__body {
      margin-top: 0px;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;

      @media only screen and (min-width: 48em) and (max-width: 64.68em) {
        margin-bottom: 65px;
        font-size: 13px;
        line-height: 16px;
      }
    }

    .feature__link {
      position: absolute;
      bottom: 45px;
      left: 30px;
      margin-top: 30px;
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
      text-transform: capitalize;

      @media only screen and (min-width: 48em) and (max-width: 64.68em) {
        bottom: 10px;
        line-height: 24px;
      }

      @media only screen and (min-width: 1441px) {
        left: 45px;
      }

      @media only screen and (max-width: 47.999em) {
        bottom: 10px;
      }
    }

    .svg-inline--fa {
      height: 1em;
      vertical-align: -0.125em;
      display: inline-block;
      width: 0.875em;
    }
  }

  .justify {
    display: flex;
    width: 100%;
    justify-content: space-between;

    @media only screen and (min-width: 48em) and (max-width: 64.68em) {
      display: grid !important;
      grid-template-columns: repeat(2, 1fr);
    }

    @media only screen and (max-width: 47.999em) {
      display: flex;
      flex-direction: column;
    }
  }

  .feature__card__visible {
    opacity: 0;
    animation: fade-in 0.25s ease-in-out 0.125s;
    animation-fill-mode: forwards;
  }

  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .black-green-theme {
    .feature__card__div {
      border: 1px solid #818181;
      background-color: #1a1a1a;
    }

    .feature__card__link {
      border: 1px solid #818181;
      background-color: #1a1a1a;
      &:hover {
        background-color: #0fd179;
      }
    }

    .feature__status {
      color: #fec800;

      &:hover {
        color: #00607a;
      }
    }

    .feature__title {
      color: #fff;
    }

    .feature__body {
      color: #fff;
    }

    .feature__link {
      color: #fff;

      &:hover {
        color: #fff;
      }
    }
  }

  .white-black-theme {
    .feature__card__div {
      border: 1px solid #d0d8df;
      background-color: #fff;
      height: auto;
    }

    .feature__card__link {
      border: 1px solid #d0d8df;
      background-color: #fff;
      &:hover {
        background-color: #0fd179;
      }
    }

    .feature__status {
      color: #1e90ba;

      &:hover {
        color: #00607a;
      }
    }

    .feature__title {
      color: #1b1b1b;
    }

    .feature__body {
      color: #1b1b1b;
    }

    .feature__link {
      color: #1b1b1b;

      &:hover {
        color: #1b1b1b;
      }
    }
  }

  .section-content.white-black-blue-theme{
    padding-left: 0;
    padding-right: 0;
  }

  .white-black-blue-theme {
    padding-top: 2rem; 
    width: max-content;

    .feature__link__arrow{
      display: none;
    }

    .feature__title{
      font-size: 22px;
    }
    
    .feature__body{
      font-size: 14px;
    }

    .feature__title, .feature__body{
      color: black;
    }

    .feature__card-wrapper{
      min-height: auto;
    }

    .feature__card__div, .feature__card__link {
      border: none;
      background-color: #fff;
      box-shadow: 0px 12px 17px 0px rgba(129, 129, 129, 0.14);
      box-shadow: 0px 5px 22px 0px rgba(129, 129, 129, 0.12);
      box-shadow: 0px 7px 8px 0px rgba(129, 129, 129, 0.2);
      width: 100%;
      max-width: 100%;
      height: 100%;

      &:hover{
        background: rgba(232, 242, 244, 1);
        transition: background-color 0.25s ease-in-out;

        .feature__link, .action-button{
          border: 1px solid black;
          color: black;
        }
      }
    }

    .feature__wrapper, .feature__link__wrapper{
      padding: 1.5rem 2rem;
      min-height: 16rem;

      @media only screen and (min-width: 48em) and (max-width: 64.68em) {
        margin-bottom: 0;
      }  
    }

    .feature__link, .action-button {
      display: block;
      border: 1px solid var(--primary-500, #0094bd);
      color: var(--primary-500, #0094bd);
      border-radius: 3px;
      padding: 0.5rem 1rem;
      background-color: white;
      font-size: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      cursor: pointer;
      text-transform: none;
    }

    .action-button {
      line-height: 20px;
    }

    .feature__link, .contentful-button__action {
      max-width: 60%;
      position: absolute;
      bottom: 15px;
      left: 20px;

      @media only screen and (min-width: 48em) and (max-width: 64.68em) {
        bottom: 21px;
        left: 28px;

        .action-button {
          line-height: 24px;
        }
      }
    }

    // .product-boxes{
    //   grid-template-columns: repeat(3, 388px);
    //   @media only screen and (max-width: 76em) {
    //     grid-template-columns: repeat(2, 350px);
    //   }
    // }
  }

  .section-content.transparent-theme {
    padding-top: 60px;
    margin-bottom: -60px;

    @media only screen and (min-width: 48em) and (max-width: 64.68em) {
      margin-bottom: -90px;
    }

    @media only screen and (max-width: 47.999em) {
      max-width: 340px !important;
      padding: 0;
      padding-top: 40px;
      margin-bottom: -110px;
    }
  }

  .transparent-theme {
    .feature__card__div {
      background-color: none;
      height: 16rem;

      &:first-child {
        @media only screen and (max-width: 47.999em) {
          padding-top: 30px;
        }
      }
    }

    .feature__card__link {
      background-color: none;
      &:hover {
        background-color: #0fd179;
      }
    }

    .feature__status {
      color: #fec800;

      &:hover {
        color: #00607a;
      }
    }

    .feature__title {
      color: #fff;
      font-weight: 600;
    }

    .feature__body {
      color: #fff;
      margin-bottom: 0 !important;

      @media only screen and (max-width: 64.68em) {
        margin-bottom: 0 !important;
        padding-bottom: 30px;
      }
    }

    .feature__link {
      color: #fff;

      &:hover {
        color: #fff;
      }
    }

    .with-top-white-line {
      position: relative;
      padding-top: 5px !important;
    }

    .with-top-white-line::before {
      content: "";
      display: block;
      position: absolute;
      top: -7px;
      left: 0;
      width: 61px;
      height: 1px;
      background-color: #fff;
    }

    .with-top-white-line--markdown::before {
      top: 7px;
    }

    .feature__wrapper {
      @media only screen and (max-width: 47.999em) {
        padding: 0 0 30px 0 !important;
      }
    }

    .product-boxes {
      row-gap: 0;
    }
  }
}
