@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600&family=Roboto:wght@100;300&family=Source+Sans+Pro:wght@300&display=swap");

.main {
  font-family: "Inter", Arial, Helvetica, sans-serif;

  &__grey-line {
    position: fixed;
    z-index: 1;
    left: 75px;
    width: 1px;
    min-height: 100%;
    background-color: #c6c6d099;

    @media only screen and (min-width: 48em) and (max-width: 64.68em) {
      left: 56px;
    }

    @media only screen and (max-width: 47.999em) {
      display: none;
    }
  }
  ol {
    ol {
        list-style-type: upper-alpha;
    
        ol {
          list-style-type: lower-roman;
        }
      }
  }  
}
