.prod__section-wrapper {
  position: relative;
  background-color: #e4f2ff;

  .rectangle {
    position: absolute;
    top: 90px;
    left: 0;
    width: 75px;

    @media only screen and (min-width: 48em) and (max-width: 64.68em) {
      top: 85px;
      width: 57px;
    }

    @media only screen and (max-width: 47.999em) {
      display: none;
    }
  }

  .rectangle--pink {
    background-color: #eb45d6;
  }

  .rectangle--second {
    height: 138px;

    @media only screen and (min-width: 48em) and (max-width: 64.68em) {
      height: 100px;
    }
  }

  .section-content {
    display: -ms-flexbox;
    display: flex;
    position: relative;
    -ms-flex-pack: justify;
    justify-content: space-between;
    max-width: 1440px;
    margin: 0;
    padding: 0 118px 48px;

    @media only screen and (min-width: 1441px) {
      margin: 0 auto;
      padding-bottom: 60px;
    }

    @media only screen and (min-width: 48em) and (max-width: 64.68em) {
      max-width: 672px;
      margin: 0 auto;
      padding-bottom: 0;
    }

    @media only screen and (max-width: 47.999em) {
      max-width: 320px;
      margin: 0 auto;
      padding: 0;
    }
  }

  .section-content--column {
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .state-header {
    z-index: 2;
    max-width: 920px;
    margin-top: 78px;
    margin-bottom: 22px;
    font-size: 54px;
    font-weight: 700;
    letter-spacing: -2px;
    line-height: 64px;
    color: #0d3244;

    @media only screen and (min-width: 48em) and (max-width: 64.68em) {
      margin-bottom: 15px;
      font-size: 36px;
      line-height: 44px;
    }

    @media only screen and (max-width: 47.999em) {
      margin-top: 45px;
      margin-bottom: 15px;
      font-size: 28px;
      line-height: 34px;
      text-align: center;
    }
  }

  .state-subheader {
    z-index: 2;
    margin-top: 0px;
    margin-bottom: 45px;
    font-size: 40px;
    font-weight: 300;
    line-height: 48px;
    color: #0d3244;

    @media only screen and (min-width: 48em) and (max-width: 64.68em) {
      margin-bottom: 15px;
      font-size: 24px;
      line-height: 48px;
    }

    @media only screen and (max-width: 47.999em) {
      max-width: 275px;
      margin: 0 auto 50px;
      font-size: 24px;
      line-height: 29px;
      text-align: center;
    }
  }

  .state-subheader--reduced-bottom-margin {
    margin-bottom: 32px;

    @media only screen and (max-width: 47.999em) {
      margin-bottom: 30px;
    }
  }

  .state-info-body {
    margin-bottom: 20px;
    color: #6e6e83;
    font-size: 20px;
    font-weight: 400;
    line-height: 32px;
    margin-top: 0px;

    @media only screen and (min-width: 48em) and (max-width: 64.68em) {
      margin-bottom: 10px;
      font-size: 18px;
      line-height: 26px;
    }

    @media only screen and (max-width: 47.999em) {
      font-size: 16px;
      line-height: 24px;
      text-align: center;
    }
  }

  .state-info-body--increased-bottom-margin {
    margin-bottom: 52px;

    @media only screen and (min-width: 48em) and (max-width: 64.68em) {
      margin-bottom: 48px;
    }
  }

  .terms {
    margin-top: 0px;
    margin-bottom: 148px;
    color: #6e6e83;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;

    @media only screen and (min-width: 48em) and (max-width: 64.68em) {
      margin-bottom: 114px;
    }
  }
}

.product-card {
  display: block;
  position: relative;
  border: 0.8px solid #e6e6e6;
  border-radius: 6px;
  background-color: #fff;
  box-shadow: 0 15px 12px 0 rgba(27, 27, 27, 0.22);
  margin-bottom: 30px;

  .product-card__overview-wrapper {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 14px 16px 12px;
  }

  .product-card__overview-content {
    -ms-flex-positive: 1;
    flex-grow: 1;
    cursor: pointer;
    margin-right: 8px;
    text-decoration: none;
  }

  .product-card__header {
    margin-bottom: 5px;
    color: #1b1b1b;
    font-size: 18px;
    font-weight: 700;
  }

  .product-card__content {
    margin-top: 0px;
  }

  .product-card__content {
    color: #1b1b1b;
    font-size: 16px;
    text-transform: none;
    font-weight: 400;
  }

  @media only screen and (max-width: 47.999em) {
    .assets-wrapper {
      height: 260px;
      text-align: center;
    }

    .assets-content {
      margin-bottom: 25px;
    }
  }

  .product-link {
    color: #00607a;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-transform: capitalize;
    margin-bottom: 20px;

    @media only screen and (max-width: 47.999em) {
      margin-bottom: 30px;
    }

    .svg-inline--fa {
      height: 1em;
      vertical-align: -0.125em;
      display: inline-block;
      width: 0.875em;
    }
  }

  .publisher-bar,
  .product-card__header {
    color: #1b1b1b;
  }

  .product-card__footer-wrapper {
    display: -ms-flexbox;
    display: flex;
    border-top: 1px solid #d0d8df;
    border-bottom: 1px solid #d0d8df;
  }

  .product-card__footer {
    display: -ms-flexbox;
    display: flex;
    padding: 6px 12px 0 15px;
    border-bottom-width: 1px;
    border-top-width: 1px;
    border-color: #d0d8df;
    background-color: #fff;
  }

  .publisher-image {
    position: relative;
    top: -12px;
    width: 28px;
    height: 28px;
    margin-right: 8px;
    padding: 2px;
    border: 1px solid #d0d8df;
    background: #fff;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.13);
  }

  .publisher-bar {
    text-transform: uppercase;
    font-weight: 400;
    font-size: 12px;
    line-height: 2.3;
  }

  .publisher-name {
    font-weight: 500;
  }
}
