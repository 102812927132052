.data-providers {
  .light-theme {
    background-color: #f5f5fa;

    .accordion-heading {
      color: #09062a;
    }

    .accordion-border {
      border-top: 1px solid #dddde5;
    }

    .accordion-item {
      border-bottom: 1px solid #dddde5;
    }

    .card__icon {
      border-bottom: 1px solid #08062a;
    }

    .card__text {
      a {
        color: #08062a;
      }
    }
  }

  .cards-row {
    max-width: 1248px;
    margin: 0 auto;
    padding: 16px 118px;
    display: flex;
    justify-content: space-between;

    @media (max-width: 78rem) {
      max-width: auto;
      padding: 16px 118px;
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  .card-container {
    display: flex;
    flex-direction: column;
    padding: 12px;

    @media (max-width: 78rem) {
      max-width: auto;
      padding: 12px;
      flex-wrap: wrap;
    }
  }

  .card__icon {
    max-width: 222px;
    margin: 0 auto 24px;

    @media (max-width: 48rem) {
      width: auto;
    }
  }

  .card__text {
    text-align: center;

    a {
      margin-top: 32px;
      margin-right: 0;
      text-transform: capitalize;
      font-size: 16px;
    }
  }
}