@import "/src/shared-styles/variables/general.scss";

.footer-container {
  display: flex;
  position: relative;
  z-index: 100;
  padding: 40px 0;
  background: $color-black;
  width: 100%;
  font-family: "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans-serif;

  @media (max-width: 1224px) {
    flex-direction: column;
  }
}

.footer-left-section {
  width: 650px;
  display: flex;
  flex-direction: column;

  @media (max-width: 1224px) {
    width: initial;
  }
}

.footer-right-section {
  display: flex;
  flex-direction: column;
  margin: auto 30px 22px auto;

  @media (max-width: 1224px) {
    margin: 0 auto;
  }
}

.footer-columns {
  padding: 0 35px 40px;
  display: flex;
  justify-content: space-between;

  @media (max-width: 1224px) {
    flex-direction: column;
  }
}

.footer-title {
  margin: 0 0 9px;
  padding-bottom: 2px;
  color: $color-turqoise;
  font-size: 16px;
  text-transform: uppercase;
}

.footer-column {
  margin-bottom: 15px;
}

.footer-links {
  padding-left: 0;
}

.footer-item {
  margin-bottom: 13px;
  color: $color-turqoise;
  font-size: 16px;
  line-height: 1.3em;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  cursor: pointer;
  list-style: none;

  @media (max-width: 1224px) {
    flex-direction: column;
    font-weight: 400;
  }

  &--grey {
    font-size: 12px;
    color: $color-grey;

    @media (max-width: 1224px) {
      white-space: nowrap;
      margin-right: 5px;
    }
  }
}

.footer-link {
  color: $color-turqoise;
  text-decoration: none;

  &:hover {
    color: $color-turqoise;
    text-decoration: none;
  }
}

.footer-bottom-row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (max-width: 1224px) {
    justify-content: initial;
    width: initial;
    max-width: 100%;
    padding: 0 35px;
  }
}

.footer-bottom-link {
  color: $color-grey;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 500;

  @media (max-width: 1224px) {
    margin-right: 10px;
  }
}

.logo-text {
  padding: 5px;
  color: $color-grey;
  font-family: "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
}
