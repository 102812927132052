@import '/src/shared-styles/variables/general.scss';

.adp-signup-modal {
  .formItem {
    flex: 50%;
    box-sizing: border-box;
    padding: 0 1.5rem 0.5rem 0.75rem;
    &:nth-child(odd) {
      max-width: 50%;
      padding-left: 1.5rem;
      padding-right: 0.75rem;
    }
    @media only screen and (max-width: 47.999em) {
      flex: 100%;

      &:nth-child(odd) {
        max-width: 100%;
      }
    }
  }
  .formContainer {
    padding: 0.5rem 0;
  }
  .formItemContainer {
    display: flex;
    flex-wrap: wrap;
    padding: 0.5rem 0;
  }
  .formRow {
    flex: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  .formHeaderContainer {
    padding: 0 1.5rem;
  }

  .formHead {
    color: $color-grey-dark;
    font-size: 1.875rem;
    line-height: 1em;
    font-weight: $font-weight-light;
    font-family: $font-family-rubik;
    margin-bottom: 0;
    margin-top: 10px;
  }
  .formHint {
    color: $color-bright-blue;
    font-size: 1.125rem;
    font-weight: $font-weight-light;
    font-family: $font-family-rubik;
    margin-top: -10px;
    margin-bottom: 5px;
  }
  .formHeadSub {
    color: $color-grey-xx-dark;
    font: 0.875rem;
    font-family: $font-family-roboto;
    font-weight: $font-weight-light;
    margin-top: 5px;
  }
  .formError {
    border: 1px solid #f9585f;
  }
  .formErrorMsg {
    display: block;
    margin-top: 0.25rem;
    color: #f8333c;
  }
  .formSubmit {
    padding: 0.5rem 1.5rem 1.5rem;
    width: 100%;
  }

  .oktaHint {
    padding: 0 1.5rem;
    font-family: $font-family-quantico;
  }
  .oktaHintHead {
    font-weight: $font-weight-bold;
    margin-bottom: 0px;
  }
  .oktaHintSub {
    line-height: 1.625rem;
    margin-top: 5px;
  }
}
