.sidebox-main {
  font-family: "Inter", Arial, Helvetica, sans-serif;
}

.section-content {
  font-family: "Inter", Arial, Helvetica, sans-serif;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -ms-flex-pack: justify;
  justify-content: space-between;
  max-width: 1440px;
  margin: 0 auto;

  @media only screen and (max-width:  64.68em) {
    display: block;
    width: 100%;
  }
}
