@import "/src/shared-styles/variables/general.scss";

.add-users-modal-header {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 24px;
}

.user-pending {
  margin: 0 0 2.5px 5px;
  padding: 3.5px 5px 2.5px;
  border-radius: 2px;
  font-family: $font-family-roboto;
  font-size: 10px;
  font-weight: $font-weight-bold;
  text-transform: uppercase;
}

.add-user-metadata-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  font-family: $font-family-quantico;
  text-transform: uppercase;
}


.selection-status {
  margin-bottom: 5px;
  font-family: $font-family-roboto;
  font-size: 14px;
  font-weight: normal;
  text-transform: none;
}

.add-users-modal-container {
    max-height: 486px;
    overflow-y: auto;

  .user-details {
    display: flex;
    border-bottom: 1px solid #cecece;
    padding: 20px 0;

    .user-details-information {
      display: flex;
      width: 100%;
      justify-content: space-between;

      .user-name {
        color: $color-grey-extra-dark;
        font-family: $font-family-roboto;
        font-size: 15px;
        font-weight: $font-weight-bold;
        margin-top: 0;
        margin-bottom: 0;
      }

      .user-email {
        color: $color-grey-extra-dark;
        font-family: $font-family-roboto;
        font-size: 14px;
        margin-top: 0;
        margin-bottom: 0;
      }

      .org-checkbox {
        position: absolute;
        z-index: -1000;
        left: -1000px;
        width: 1px;
        height: 1px;
        margin: -1px;
        padding: 0;
        overflow: hidden;
        border: 0;
        clip-path: inset(0 0 0 0);
      }  

      .org-checkbox+.org-label {
        display: inline-block;
        height: 39px;
        padding-left: 44px;
        background-repeat: no-repeat;
        background-position: 0 0;
        font-size: 39px;
        line-height: 39px;
        vertical-align: middle;
        cursor: pointer;
      }

      .org-checkbox:checked+.org-label {
        background-image: url('./images/unchecked.svg');
      }

      .org-label {
        background-image: url('./images/checked.svg');
        user-select: none;
      }
    }

  }
}
