@import "/src/shared-styles/variables/general.scss";

.login-to-bookmark-modal {
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.login-to-bookmark-modal__text {
  margin-bottom: 20px;
  color: $color-grey-dark;
  font-family: $font-family-roboto;
  font-size: 18px;
  font-weight: 300;
}
