@import '/src/shared-styles/variables/general.scss';

.fact-row {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  margin-bottom: 112px;
  max-width: 1440px;

  @media #{$media-wide} {
    padding: 0 60px;
  }
  
  @media #{$media-medium} {
    max-width: 672px;
  }
  
  @media #{$media-small} {
    max-width: 346px;
  }
}

.fact-wrapper {
  max-width: 55%;

  @media #{$media-medium} {
    margin-right: 40px;
    max-width: 45%;
  }
  
  @media #{$media-small} {
    max-width: 100%;
  }

  &--full-width {
    @media #{$media-medium} {
      max-width: 100%;
      margin-right: 0;
    } 
  }
}

.with-top-blue-line {
  position: relative;
  padding-top: 15px !important;
}

.with-top-blue-line::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 30px;
  height: 1.5px;
  background-color: $color-vibrant-turqoise;
}

.mp-header {
  font-size: 40px;
  font-family: $font-family-rubik;
  font-weight: $font-weight-light;
  margin-bottom: 0;
  line-height: 50px;

  &--no-margin-top {
    margin-top: 0;
  }

  @media #{$media-medium} {
    font-size: 32px;
  }
  
  @media #{$media-small} {
    font-size: 28px;
    margin-bottom: 24px;
  }
}

.mp-body {
  font-size: 20px;
  font-family: $font-family-roboto;
  font-weight: $font-weight-light;
  line-height: 40px;

  @media #{$media-medium} {
    margin-bottom: 44px;
  }
  
  @media #{$media-small} {
    margin-bottom: 48px;
  }
}

.sidebar-section {
  margin-bottom: 35px;
}

.hidden {
  display: none;
}

// BUTTON STYLES
.b-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 0;
  border-radius: 4px;
  font-family: $font-family-roboto;
  font-weight: 600;
  text-align: center;
  transition: all 0.15s linear, visibility 0ms;
  line-height: 1.5;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
  appearance: none;
  padding: 0.6em 1.5em 0.67em;

  &:hover, :active {
    text-decoration: none;
  }

  &:focus {
    outline: none;
  }

  &:disabled, .is-disabled {
    cursor: default;
  }
}

.b-button--blue {
  background-color: $color-vibrant-turqoise;
  color: $color-white;
  border: 1px solid $color-vibrant-turqoise;

  &:hover, :active, :focus {
    color: $color-white;
    background-color: $color-bright-turqoise;
    border: 1px solid $color-bright-turqoise;
  }
}

.b-button--black-text {
  background-color: $color-white;
  color: $color-black;
  font-weight: normal;
  padding: 9px 18px;

  &:hover, :focus, :active {
    background-color: $color-black;
    color: $color-white;
  }
}

.b-button--orange {
  font-family: $font-family-roboto;
  font-weight: 600;
  text-transform: uppercase;
  background-color: $color-orange;
  color: $color-white;
  border: 1px solid $color-orange;
  border-radius: 4px;
  font-weight: normal;
  padding: 9px 18px;

  &:hover, :active, :focus {
    color: $color-white;
  }
}

.b-button--orange-text {
  background-color: $color-white;
  color: $color-orange;
  font-weight: normal;
  padding: 9px 18px;
}

.b-button--orange-text:hover,
.b-button--orange-text.active,
.b-button--orange-text:focus {
  color: $color-orange;
}

.b-button--yellow {
  background-color: $color-yellow;
  color: $color-black;
}

.b-button--yellow:hover,
.b-button--yellow.active,
.b-button--yellow:focus {
  background-color: $color-black;
  color: $color-yellow;
}

/* WHITE EMPTY BUTTON STYLING */

.b-button--white-empty {
  background-color: $color-transparent;
  color: $color-white;
  border: 1px solid $color-white;
}

.b-button--white-empty:hover,
.b-button--white-empty.active,
.b-button--white-empty:focus {
  background-color: $color-white;
  color: $color-black;
  border: 1px solid $color-white;
}

.b-button__arrow--left {
  margin-top: 5px;
  margin-right: 5px;
}
