.contentful-top-tech-section {
  .page-section {
    padding-bottom: 0;
  }

  .u-margin-bottom-40 {
    margin-bottom: 40px !important;
  }

  .fact-row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin: 0 auto 112px;
    max-width: 1440px;

    @media only screen and (min-width: 64.68em) and (max-width: 1680px) {
      padding: 0 118px;
    }

    @media only screen and (min-width: 48em) and (max-width: 64.68em) {
      max-width: 672px;
    }

    @media only screen and (max-width: 47.999em) {
      display: block;
      max-width: 346px;
    }
  }

  .__aaee5__content-titles {
    color: #000;
    font-size: 40px;
    font-weight: 300;
    line-height: 1.6;
    margin-top: 120px;
    margin-bottom: 10px;
  }

  .__aaee5__display-restriction {
    display: block;
  }

  .mb-4 {
    margin-bottom: 16px;
  }

  .grid {
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    -ms-flex-direction: column;
    -ms-flex-pack: start;
    justify-content: flex-start;
    max-width: 1080px;
    margin: 0 auto;
    padding: 0 15px;

    @media only screen and (min-width: 64.68em) {
      flex-direction: row;
      -ms-flex-direction: row;
    }
  }

  .supported-item-api img {
    margin-left: 7px;
  }

  .__aaee5__width-restriction {
    max-width: 100%;
  }

  .grid--with-no-padding {
    padding: 0;
  }

  .grid__column {
    z-index: 1;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;

    width: 100%;
    min-width: 100px;
  }

  .grid__column--1-of-5 {
    -ms-flex-preferred-size: 18%;
    flex-basis: 18%;
    width: 20%;

    @media only screen and (min-width: 64.68em) and (max-width: 1440px) {
      justify-content: space-evenly;
    }

    @media only screen and (min-width: 64.68em) {
      padding: 15px;
    }

    @media (max-width: 64.68em) {
      -ms-flex-preferred-size: 100%;
      flex-basis: 100%;
      width: 100%;
      flex-direction: column;
      -ms-flex-direction: column;
      justify-content: center;
      margin-top: 20px;
      box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
    }
  }

  .supported-item {
    position: relative;
    top: 0;
    min-height: 132px;
    padding: 20px 0;
    transition: 0.1s;
    border-radius: 1px;
    color: rgba(255, 255, 255, 0.75);
    text-align: center;
    min-width: 120px;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);

    img {
      margin-bottom: 22px;
    }

    &:hover {
      position: relative;
      top: -3px;
      transition: 0.1s;
      box-shadow: 0 10px 45px rgba(0, 0, 0, 0.23);
    }

    @media only screen and (min-width: 64.68em) {
      min-width: 160px;
    }
  }

  .__aaee5__background-tool-overview {
    border-radius: 3px;
    background-image: linear-gradient(to right, #f3fbfc 0, #f8f5fa 100%);
  }

  .__aaee5__tool-list-blue {
    color: #1e90ba;
    font-size: 26px;
    font-weight: 300;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .__aaee5__footer,
  .__aaee5__library-list {
    font-weight: 500;
  }

  .__aaee5__library-list {
    color: #000;
    font-size: 18px;
  }

  .__aaee5__footer {
    color: #309bc4;
    font-size: 24px;
    margin-bottom: 0;
    padding-bottom: 75px;
    line-height: 1.2;
  }

  .__aaee5__tool-list-black {
    border-radius: 3px;
    box-shadow: inset -1px -1px 0 0 #a7a9ac;
    color: #1b1b1b;
    font-size: 14px;
  }

  .u-vertically-spaced {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .u-vertically-spaced > .u-vertically-spaced__item:first-child {
    margin-top: 0;
  }

  .u-vertically-spaced > .u-vertically-spaced__item:last-child {
    margin-bottom: 0;
  }

  .u-vertically-spaced
    > .u-vertically-spaced__item.u-vertically-spaced__item--with-extra-space-below {
    margin-bottom: 10px;
  }

  .u-vertically-spaced
    > .u-vertically-spaced__item.u-vertically-spaced__item--with-extra-space-above {
    margin-top: 10px;
  }

  .u-cross-axis-left-aligned {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }

  .link-boxes a {
    background: #f2f2f2;
    padding: 12px 21px 9px;
    border: 1px solid #e4e9ee;
  }

  .link-boxes h4 {
    padding-top: 30px;
  }

  .u-margin-bottom-10 {
    margin-bottom: 10px !important;
  }

  .u-horizontally-spaced {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: center;
    align-items: center;
    margin-right: -10px;
    margin-bottom: -20px;
    margin-left: -10px;
  }

  .u-horizontally-spaced > .u-horizontally-spaced__item {
    margin: 0 10px 20px;
  }
}
