.contentful__sidebox-wrapper {
  .fixed-wrapper--active {
    position: sticky;
    top: 0px;
    right: 0px;
    width: 1440px;
    max-width: 1440px;

    @media only screen and (min-width: 48em) and (max-width: 64.68em) {
      display: none;
    }

    @media only screen and (max-width: 47.999em) {
      display: none;
    }

    h2 {
      margin-top: 0px;
      margin-bottom: 15px;
      color: #fff;
      font-size: 29px;
      font-weight: 600;
      line-height: 1.4;
    }

    img {
      width: 220px;
      height: 275px;
      margin: 0 auto 15px;
    }

    p {
      margin-top: 0px;
      margin-bottom: 10px;
      color: #fff;
      font-size: 18px;
      line-height: 1.6;
    }
  }

  .container {
    margin-top: 120px;
    max-width: 351px;
    max-height: 522px;
  }

  .fixed-sidebar {
    padding: 25px 70px;
    border: 1px solid #e6e6e6;
    background-image: linear-gradient(146deg, #0090ba, #004b61 98%);
    text-align: center;
    box-shadow: 0 15px 12px 0 rgba(27, 27, 27, 0.22);
  }
}

.__3f1eb__sticky-header {
  display: flex;
  visibility: hidden;
  position: fixed;
  z-index: 9999;
  top: 0;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 112px;
  transition: visibility 0.5s, opacity 0.5s linear;
  opacity: 0;
  background-image: linear-gradient(98deg, #0090ba, #004b61 98%);
  color: #fff;
  font-size: 18px;
  object-fit: cover;
  box-shadow: 0 15px 12px 0 rgba(27, 27, 27, 0.22);
  overflow: hidden;

  @media only screen and (max-width: 47.999em) {
    width: 100vw;
    height: 90px;
  }

  h2 {
    display: none;
  }

  img {
    order: 3;
    width: 240px;
    height: auto;
    object-fit: cover;
    position: relative;
    top: 70px;
    transform: rotate(20deg);

    @media only screen and (max-width: 47.999em) {
      width: 150px;
      top: 30px;
    }
  }

  .my-flex-container {
    display: flex;
    align-items: center;

    @media only screen and (max-width: 47.999em) {
      flex-direction: column;
      align-items: flex-start;
      padding-bottom: 10px;
      padding-left: 10px;
    }
  }

  p {
    padding: 30px;
    line-height: 1.6;
    max-width: 250px;

    @media only screen and (max-width: 47.999em) {
      max-width: 170px;
      line-height: 1.4;
      padding: 0;
      padding-bottom: 7px;
      font-size: 14px;
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  a {
    width: 150px;
    font-size: 15px;
    font-weight: 700;
    white-space: nowrap;

    @media only screen and (max-width: 47.999em) {
      width: 120px;
      padding: 6px 8px;
      font-size: 12px;
    }
  }
}

.__3f1eb__sticky-header--revealed {
  @media only screen and (max-width: 64.68em) {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.5s, opacity 0.5s linear;
  }
}

.__3f1eb__sticky-header--revealed.visible {
  @media only screen and (max-width: 64.68em) {
    visibility: visible;
    opacity: 1;
  }
}
