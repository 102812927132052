.contentful__products-grid-wrapper {
  & .wrapper {
    max-width: 1440px;
    margin: auto;
    padding: 0 118px;

    @media only screen and (min-width: 48em) and (max-width: 64.68em) {
      max-width: 672px;
    }

    @media only screen and (max-width: 47.999em) {
      max-width: 346px;
    }
  }

  .featured-datasets__wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 81px;
    align-items: center;
    height: auto !important;

    @media only screen and (max-width: 47.999em) {
      display: initial;
    }
  }

  .data-card {
    min-height: 200px;
    margin-bottom: 40px;
    border: 1px solid #e6e6e6;
    border-radius: 6px;
    box-shadow: 0 15px 12px 0 rgba(27, 27, 27, 0.22);
    flex: 0 0 calc(50% - 30px);

    @media only screen and (min-width: 64.68em) and (max-width: 81em) {
      flex: 0 0 calc(50% - 30px);
      max-width: 680px;
    }

    @media only screen and (min-width: 48em) and (max-width: 64.68em) {
      max-height: 300px;
      flex: 0 0 100%;
    }

    @media only screen and (max-width: 47.999em) {
      max-width: 346px;
      margin: 0 auto 24px;
    }

    .main {
      max-height: 220px;
      padding: 20px;
    }

    .link__content {
      cursor: pointer;
      text-decoration: none;
      text-transform: none;
      &:hover {
        text-decoration: none;
        text-transform: none;
      }
    }

    .title {
      margin-bottom: 9px;
      color: #1b1b1b;
      font-size: 18px;
      font-weight: 500;
      text-transform: none;
      font-size: 18px;
      font-weight: 500;
      margin-top: 0px;
    }

    .description {
      color: #1b1b1b;
      font-size: 16px;
      line-height: 1.38;
      text-transform: none;
      font-size: 16px;
      font-weight: 400;
      margin-top: 0px;
      display: -webkit-box;
      overflow: hidden;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
    }

    .tag {
      width: 172px;
      margin-top: 18px;
      border: 1px solid #05b888;
      border-radius: 10px;
      color: #0d3244;
      font-size: 10px;
      font-weight: 500;
      display: flex;

      .tag--premium {
        padding: 3px 6px 2px 10px;
        background-color: rgba(5, 184, 136, 0.1);
      }

      .tag--sample-data {
        padding: 3px 6px 2px;
        border-left: 1px solid #05b888;
        font-weight: 300;
      }
    }
  }

  .products-grid__image-card {
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    -ms-flex-preferred-size: 48%;
    flex-basis: 48%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: justify;
    justify-content: space-between;
    min-height: 200px;
    margin-bottom: 40px;
    border-radius: 6px;
    box-shadow: 0 15px 12px 0 rgba(27, 27, 27, 0.22);
    flex: 0 0 calc(50% - 30px);

    cursor: pointer;
    text-decoration: none;
    text-transform: none;
    &:hover {
      text-decoration: none;
      text-transform: none;
    }

    @media only screen and (min-width: 64.68em) and (max-width: 81em) {
      flex: 0 0 calc(50% - 30px);
      max-width: 680px;
    }

    @media only screen and (min-width: 48em) and (max-width: 64.68em) {
      max-height: 300px;
      flex: 0 0 100%;
    }

    @media only screen and (max-width: 47.999em) {
      max-width: 346px;
      margin: 0 auto 63px;
    }

    .main {
      padding: 30px;
    }
  }

  .alt-data-cta__title {
    margin-bottom: 10px;
    margin-top: 0px;
    color: #fff;
    font-size: 24px;
    font-weight: 300;
    text-transform: none;
    font-size: 24px;
    line-height: 1.4;
  }

  .alt-data-cta__description,
  .alt-data-cta__link {
    line-height: 1.38;
    text-transform: none;
    color: #fff;
    font-weight: 400;
  }

  .alt-data-cta__description {
    margin-top: 0px;
    margin-bottom: 20px;
    font-size: 13px;
    text-transform: none;
  }

  .alt-data-cta__link {
    font-size: 16px;
    text-transform: uppercase;
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
  }

  .arrow--right {
    margin-left: 5px;
  }

  .alt-data-cta__link:hover {
    color: #fff;
  }

  .products-grid__card__visible,
  .products-grid__image-card__visible {
    opacity: 0;
    animation: fade-in 0.125s ease-in-out 0.125s;
    animation-fill-mode: forwards;

    @keyframes fade-in {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  }
}
