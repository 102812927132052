.structured-cta-box__section {
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 1440px;
  margin: 0 auto 120px;
  padding: 0 118px;

  @media only screen and (min-width: 64.68em) and (max-width: 1680px) {
  }

  @media only screen and (min-width: 48em) and (max-width: 64.68em) {
    max-width: 672px;
  }

  @media only screen and (max-width: 47.999em) {
    display: block;
    padding: 0;
    max-width: 346px;
  }

  &__left {
    max-width: 55%;

    @media only screen and (min-width: 48em) and (max-width: 64.68em) {
      padding-right: 10px;
    }

    @media only screen and (max-width: 47.999em) {
      max-width: 100%;
      padding-right: 0px;
    }

    h2 {
      font-size: 40px;
      font-weight: 300;
      margin-top: 120px;
      line-height: 1.4;
      margin-bottom: 0;

      @media only screen and (min-width: 48em) and (max-width: 64.68em) {
        font-size: 32px;
      }

      @media only screen and (max-width: 47.999em) {
        font-size: 28px;
        margin-bottom: 30px;
      }
    }

    h3 {
      color: black;
      margin-top: 20px;
      margin-bottom: 5px;
      font-size: 18px;
      font-weight: 500;
    }

    p {
      font-size: 18px;
      font-weight: 300;
      line-height: 1.6;
      margin: 4px 0 10px;

      @media only screen and (min-width: 48em) and (max-width: 64.68em) {
        margin-bottom: 45px;
      }

      @media only screen and (min-width: 48em) and (max-width: 64.68em) {
        margin-bottom: 55px;
      }
    }
  }

  &__right {
    width: 300px;
    padding: 34px 22px 43px;
    box-shadow: 0 15px 12px 0 rgb(27 27 27 / 22%);
    border: 1px solid #e6e6e6;
    margin-top: 120px;

    @media only screen and (max-width: 47.999em) {
      margin-top: 60px;
    }

    h2 {
      color: #1e90ba;
      font-weight: 300;
      margin-top: 0;
      margin-bottom: 30px;
      font-size: 20px;
      line-height: 1.14;
    }

    h2:not(:first-of-type) {
      border-top: 1px solid #e6e6e6;
      padding-top: 24px;
      margin-top: 24px;
    }

    p {
      font-weight: 300;
      margin-bottom: 10px;
      font-size: 14px;
      line-height: 1.4;
      margin-top: 0;
    }

    ol {
      margin-left: -6px;
      margin-bottom: 0;
    }

    ol p {
      margin-top: 0px;
      margin-bottom: 0px;
      line-height: 1.6;
    }

    &__button {
      display: flex;
      flex-direction: column;
      text-align: center;
      margin-left: 20px;
    }
  }
}
