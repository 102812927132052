.callout-structured-section-wrapper {
  position: relative;
  background-size: cover;
  width: 100%;
  background-repeat: no-repeat;
}

.callout-less-padding {
  .callout-structured-section-content {
    padding-bottom: 0;
  }

  .callout-structured-sub-banner-body {
    margin: 0;

    @media only screen and (min-width: 48em) and (max-width: 64.68em) {
      margin-bottom: 60px;
    }
  }
}

.callout-structured-section-content {
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -ms-flex-pack: justify;
  justify-content: space-between;
  max-width: 1440px;
  margin: 0 118px;
  padding-bottom: 48px;

  @media only screen and (min-width: 1441px) {
    margin: 0 118px;
    padding-bottom: 60px;
  }

  @media only screen and (min-width: 48em) and (max-width: 64.68em) {
    max-width: 672px;
    margin: 0 118px;
    padding-bottom: 0;
  }

  @media only screen and (max-width: 47.999em) {
    max-width: 320px;
    margin: 0 auto;
    padding-bottom: 0;
  }
}

.callout-structured-sub-banner-container {
  margin: 0 auto;
  line-height: 31px;
  text-align: center;
}

.callout-structured-sub-banner-header {
  max-width: 1050px;
  margin: 120px auto 10px;
  font-size: 26px;
  font-weight: 600;
  line-height: 1.4;

  @media only screen and (min-width: 48em) and (max-width: 64.68em) {
    margin-top: 55px;
    margin-bottom: 20px;
    font-size: 18px;
    line-height: 22px;
  }

  @media only screen and (max-width: 47.999em) {
    margin-top: 45px;
    font-size: 18px;
    line-height: 24px;
  }
}

.callout-structured-sub-banner-body {
  max-width: 1000px;
  margin: 0 auto 60px;
  font-size: 26px;
  font-weight: 300;
  line-height: 1.6;

  @media only screen and (min-width: 48em) and (max-width: 64.68em) {
    margin-bottom: 46px;
    font-size: 18px;
    line-height: 22px;
  }

  @media only screen and (max-width: 47.999em) {
    margin-bottom: 40px;
    font-size: 18px;
    line-height: 24px;
  }
}
