@import "/src/shared-styles/variables/general.scss";

.privacySettingModal__wrapper {
  .privacySettingModal__header {
      margin-bottom: 30px;
      margin-top: 0px;
      font-family: $font-family-rubik;
      font-size: 24px;
      font-weight: $font-weight-light;
      line-height: 1.4;
    }
  
    .privacySettingModal__table-header {
      font-weight: $font-weight-bold;
    }
  
    .privacySettingModal__table-body-label {
      display: block;
    }
  
    .privacySettingModal__button {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      padding-top: 10px;
    }
  
    .privacySettingModal__table-body-content {
      max-width: 400px;
      color: $color-off-black;
    }
  
    table {
      border: 1px solid $color-blue-grey;
      width: 100%;
      border-collapse: collapse;
  
      th {
        font-weight: $font-weight-medium;
        text-transform: none;
        padding: 1rem 0.625rem;
        border-right: 1px solid $color-blue-grey;
      }
  
      tr.alt,
      tr:nth-of-type(odd) {
        background: $color-white;
      }
  
      tr {
        font-size: 13px;
        font-weight: $font-weight-light;
        border-bottom: 1px solid $color-blue-grey;
        padding: 0.525rem 0.5625rem;
        border-right: 1px solid $color-blue-grey;
  
        td {
          box-sizing: border-box;
          padding: 0.525rem 0.5625rem;
          min-width: 60px;
          border-right: 1px solid $color-blue-grey;
        }
      }
    }

    .b-button--blue {
      padding: 5px 15px;
      font-size: 20px;
    }
}
