.link_button {
    display: inline-block;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 700;
    margin-right: 20px;
    text-align: center;
  }

.link_button__orange_white {
  background-color: #E1701E;
  color: white;
  padding: 10px 20px;
  border: 1px solid #E1701E;
  margin-right: 20px;
  border-radius: 0.25rem;

  &:hover {
    background-color: #E1701E;
    color: white;
    border: 1px solid #E1701E;
    text-decoration: none;
  }
}

.link_button__blue_white {
  background-color: #0090BA;
  color: white;
  padding: 10px 20px;
  border: 1px solid #0090BA;
  border-radius: 0.25rem;

  &:hover {
    text-decoration: none;
    background-color: #00BFD5;
    color: white;
    border: 1px solid #00BFD5;
  }
}

.link_button__white {
  background-color: white;
  color: black;
  padding: 10px 20px;
  border-radius: 0.25rem;
  transition: all 0.075s linear,visibility 0s;

  &:hover {
    text-decoration: none;
    background-color: black;
    color: white;
  }
}

.link_button__transparent {
  background-color: transparent;
  color: white;
  padding: 10px 20px;
  border-radius: 0.25rem;
  transition: all 0.075s linear,visibility 0s;
  border: 1px solid white;

  &:hover {
    text-decoration: none;
    background-color: white;
    color: black;
  }
}

.link_button__yellow {
  background-color: #fdb913;
  color: black;
  padding: 10px 20px;
  border-radius: 0.25rem;
  transition: all 0.075s linear,visibility 0s;

  &:hover {
    text-decoration: none;
    background-color: black;
    color: #fdb913;
  }
}

.link_button__white-border {
  background-color: transparent;
  color: white;
  padding: 10px 20px;
  border-radius: 0.25rem;
  transition: all 0.075s linear, visibility 0s;
  border: 1px solid white;

  &:hover {
    text-decoration: none;
    background-color: white;
    color: black;
  }
}
