@import '/src/shared-styles/variables/general.scss';

.api-key-code {
  margin-bottom: 50px;
  &__button {
    width: 102%;
    border: none;
    background: none;
    margin-left: -5px;

    :hover {
      cursor: pointer;
    }
  }
  &__title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 14px;
    margin-bottom: 10px;
  }

  &__api-key-box {
    width: 100%;
    display: flex;
    flex-direction: row;

  }
  &__api-key {
    width: 100%;
    padding: 10px;
    text-align: initial;
    border: 1px solid #949494;
    border-radius: 0 4px 4px 0;
    background: $org-db-api-box-background;
    font-family: 'Roboto';
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
  }

  &__copy-icon {
    height: 25px;
    padding: 8px;
    border: 1px solid $org-db-copy-green;
    border-right: 0;
    border-radius: 4px 0 0 4px;
  }
}